import { useTranslation } from 'react-i18next';
import { Assignment } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const RecipeDatabase = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_PRODUCT) && {
      name: t('common.mainMenu.products'),
      to: '/products',
      additionalActivationPaths: getPathSuffixes('/products', ['edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_INGREDIENT) && {
      name: t('common.mainMenu.ingredients'),
      to: '/ingredients',
      additionalActivationPaths: getPathSuffixes('/ingredients', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_RECIPE) && {
      name: t('common.mainMenu.recipes'),
      to: '/recipes',
      additionalActivationPaths: getPathSuffixes('/recipes', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_DISH) && {
      name: t('common.mainMenu.meals'),
      to: '/meals',
      additionalActivationPaths: getPathSuffixes('/meals', [
        'add',
        'edit/:id',
        'kwg_group',
        'kwg_group/add',
        'kwg_group/edit/:id',
        'settings',
      ]),
    },
    isGranted(roles.ROLE_SHOW_DISH_PHOTO_GALLERY) && {
      name: t('dishes.gallery.title'),
      to: '/dish-photo-gallery',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.recipesDatabase')}
      icon={<Assignment />}
      links={links}
    />
  );
};

export default RecipeDatabase;
