const PATH_PREFIX = '/';

const getPathSuffixes = (path, types) => {
  if (!path.startsWith(PATH_PREFIX)) {
    throw new Error(`Path must start with ${PATH_PREFIX}`);
  }

  return types.map(type => `${path}/${type}`);
};

export default getPathSuffixes;
