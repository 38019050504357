import { matchPath } from 'react-router';

import ACTIVE_LINK_OPTIONS from '../constants/activeLinkOptions';
import pathPrefix from '../constants/pathPrefix';

const isLinkActive = (pathname, pathToCheck) =>
  !!matchPath(pathname, {
    path: `${pathPrefix}${pathToCheck}`,
    ...ACTIVE_LINK_OPTIONS,
  });

export default isLinkActive;
