import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

import withStyles from '@material-ui/core/styles/withStyles';
import { AppBar, Toolbar, Hidden } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import adminNavbarStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle';
import { navyColor } from 'assets/jss/material-dashboard-pro-react';
import ChevronIcon from 'assets/img/ChevronIcon';
import { get } from 'helpers/apiHelpers';
import { useLocation } from 'react-router-dom';
import Button from 'components/CustomButtons/Button';

import AdminNavbarLinks from './AdminNavbarLinks';

const getPathOrId = (pathname, isPath = false) => {
  const parts = pathname.split('/');
  const index =
    parts.indexOf('edit') > -1
      ? parts.indexOf('edit')
      : parts.indexOf('preview');

  return parts[index - (isPath ? 1 : -1)];
};

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  breadcrumbsWrapper: {
    paddingLeft: '15px',
    display: 'flex',
    marginLeft: '10px',
    alignItems: 'center',
    fontSize: '1.3em',
    color: navyColor,
  },
  breadrumbsItem: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  chevronIcon: {
    margin: '0 10px 0 10px',
    transform: 'rotate(-90deg)',
    width: '12px',
  },
  link: {
    cursor: 'pointer',
    color: navyColor,
    transition: '.5s',
    '&:hover': { borderBottom: '1px solid navyColor' },
  },
  editField: {
    color: 'black',
  },
  breadcrumbInnerContent: {
    display: 'flex',
    gap: '4px',
  },
});

const AdminNavbar = ({
  classes: propClasses,
  activeRoute,
  handleDrawerToggle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = useLocation();
  const [editFieldName, setEditFieldName] = useState('');
  const [subPagePath, setSubPagePath] = useState('');
  const [subPageText, setSubPageText] = useState('');

  useEffect(() => {
    setSubPageText('');
    setEditFieldName('');
    if (pathname.includes('/edit') || pathname.includes('/preview')) {
      getEditFieldName();
    }
    if (pathname.includes('/kwg-groups')) {
      setSubPagePath('/admin/meals/kwg-groups');
      setSubPageText(t('breadcrumbs.kwgGroup'));
    }
  }, [pathname]);

  const getEditFieldName = () => {
    const path = getPathOrId(pathname, true);
    const id = getPathOrId(pathname);

    // start of caterings flow related code - to be removed
    if (path === 'kitchen') {
      setEditFieldName('Przepisy do zrobienia');
      return;
    }
    if (path === 'dishes-packhouse') {
      setEditFieldName('Dania do spakowania');
      return;
    }
    if (path === 'bags-packhouse') {
      setEditFieldName('Diety do spakowania');
      return;
    }
    // end of caterings flow related code - to be removed

    let endpoint = {
      meals: 'dishes',
      types: 'meal-types',
      messages: 'notifications',
      brands: 'brands',
      'product-feed': 'feeds',
      'pages-creator': 'pages',
      'kwg-groups': 'kwg-groups',
      'ecommerce-diets': 'ecommerce-diets',
      'discount-codes': 'discount-codes',
    }[path];

    if (endpoint && id) {
      get(`/${endpoint}/${id}`).then(el => {
        if (el.name) {
          setEditFieldName(el.name);
        } else if (el.firstName) {
          setEditFieldName(`${el.firstName} ${el.lastName}`);
        } else if (el.workName) {
          setEditFieldName(el.workName);
        } else if (el.workingName) {
          setEditFieldName(el.workingName);
        } else if (el.code) {
          setEditFieldName(el.code);
        } else if (el.value) {
          setEditFieldName(el.value);
        } else if (el.title) {
          setEditFieldName(el.title);
        } else if (el.diet) {
          setEditFieldName(el.diet.name);
        } else if (el.label) {
          setEditFieldName(el.label);
        } else if (el.domain) {
          setEditFieldName(el.domain);
        } else if (el.nameForClient) {
          setEditFieldName(el.nameForClient);
        } else if (el.id) {
          setEditFieldName(el.id);
        }
      });
    }
  };

  return (
    <AppBar className={classNames(propClasses.appBar, propClasses.caterings)}>
      <Toolbar className={propClasses.container}>
        <div className={propClasses.flex}>
          <div
            className={classNames(
              propClasses.title,
              classes.breadcrumbsWrapper
            )}
            color="transparent"
          >
            <span className={classes.flex}>{activeRoute.icon}</span>
            <div className={classes.breadrumbsItem}>
              {activeRoute.sectionPath ? (
                <a className={classes.link} href={activeRoute.sectionPath}>
                  <h3>{activeRoute.sectionTitle} </h3>
                </a>
              ) : (
                <h3>{activeRoute.sectionTitle} </h3>
              )}
              {activeRoute.viewTitle && (
                <ChevronIcon className={classes.chevronIcon} />
              )}
              <a className={classes.link} href={activeRoute.viewPath}>
                <h3 className={classes.breadcrumbInnerContent}>
                  {activeRoute.viewTitle}{' '}
                  {activeRoute.breadcrumbAdditionalContent}
                </h3>
              </a>
              {subPageText && <ChevronIcon className={classes.chevronIcon} />}
              <a className={classes.link} href={subPagePath}>
                <h3>{subPageText}</h3>
              </a>
              {editFieldName && <ChevronIcon className={classes.chevronIcon} />}
              <h3 className={classes.editField}>{editFieldName}</h3>
            </div>
          </div>
        </div>
        <Hidden xsDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={propClasses.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  brandText: PropTypes.string,
  location: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  breadcrumbAdditionalContent: PropTypes.node,
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
