import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { DeleteForever } from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControl from '@material-ui/core/FormControl';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { ReactComponent as CopyIcon } from 'assets/img/CopyIcon.svg';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { grayColor } from 'assets/jss/material-dashboard-pro-react';

const InputMaskStyle = {
  defaultMask: {
    marginTop: '10px',
    border: 'none',
    borderBottom: `1px solid ${grayColor[4]}`,
    padding: '5px',
  },
};

const PostCodeRow = ({
  t,
  errors,
  classes,
  copyRow,
  removeRow,
  updateRow,
  removeError,
  postCodeData,
  canBeRemoved,
  shortFormEnabled,
  postCodeSymbol,
}) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          {canBeRemoved && (
            <DeleteForever
              data-cy="new-zones_post-codes-modal_delete"
              onClick={() => removeRow(postCodeData)}
              style={{
                position: 'absolute',
                left: '-10px',
                top: '20px',
                zIndex: 22,
                background: '#989898',
                color: '#fff',
                borderRadius: '20px',
                padding: 2,
                cursor: 'pointer',
              }}
            />
          )}

          <CopyIcon
            data-cy="new-zones_post-codes-modal_copy-button"
            onClick={() => copyRow(postCodeData)}
            style={{
              position: 'absolute',
              left: '-10px',
              top: '-10px',
              zIndex: 22,
              background: '#989898',
              color: '#fff',
              borderRadius: '20px',
              padding: 3,
              cursor: 'pointer',
              fill: 'currentColor',
              width: '1em',
              height: '1em',
              display: 'inline-block',
              'font-size': '24px',
              transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              userSelect: 'none',
            }}
          />

          <GridItem xs={12} sm={6} md={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t('zones.postCode', 'Kod pocztowy')}
            </FormLabel>
            <FormControl fullWidth>
              <InputMask
                data-cy="new-zones_post-codes-modal_post-code"
                className={classes.defaultMask}
                name="postCode"
                value={postCodeData.postCode}
                type="text"
                onChange={e => {
                  removeError(postCodeData._key, e.target.name);
                  updateRow(postCodeData._key, e);
                }}
                inputSize={12}
                errors={errors}
                mask={postCodeSymbol}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t('zones.city', 'Miejscowość')}
            </FormLabel>
            <FormControl fullWidth>
              <FormTextInput
                data-cy="new-zones_post-codes-modal_city"
                classes={classes}
                name="city"
                value={postCodeData.city}
                type="text"
                handleChange={e => {
                  updateRow(postCodeData._key, e);
                }}
                inputSize={12}
                errors={errors}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t('zones.street', 'Ulica')}
            </FormLabel>
            <FormControl fullWidth>
              <FormTextInput
                data-cy="new-zones_post-codes-modal_street"
                classes={classes}
                name="street"
                value={postCodeData.street}
                type="text"
                handleChange={e => {
                  if (!shortFormEnabled) {
                    updateRow(postCodeData._key, e);
                  }
                }}
                inputSize={12}
                errors={errors}
                disabled={shortFormEnabled}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t('zones.buildingNumber', 'Numer budynku')}
            </FormLabel>
            <FormControl fullWidth>
              <FormTextInput
                data-cy="new-zones_post-codes-modal_bulding-number"
                classes={classes}
                name="buildingNumber"
                value={postCodeData.buildingNumber}
                type="text"
                handleChange={e => {
                  if (!shortFormEnabled) {
                    updateRow(postCodeData._key, e);
                  }
                }}
                inputSize={12}
                disabled={shortFormEnabled}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    postCodeSymbol: state.Brands.selectedRegionForValidations.postCodeMask[0],
  };
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  InputMaskStyle
);
const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps)
);

export default enhance(PostCodeRow);
