import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

import { changeSidebarStatus } from 'actions/SidebarStatus';

import SidebarItem from '../SidebarItem/SidebarItem';
import MinimizeButton from './MinimizeButton';

const useStyles = makeStyles({
  sidebarItem: {
    display: 'flex',
  },
  minimizeButtonWrapper: {
    padding: '3px 10px 0 0',
  },
  minimizeButtonWrapperMini: {
    margin: '5px 0 10px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  link: {
    transition: 'all 300ms linear',
    display: 'block',
    opacity: '1',
    transform: 'translate3d(0, 0, 0)',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '5px',
    fontSize: '14px',
    whiteSpace: 'normal',
    fontWeight: '400',
    lineHeight: '30px',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    width: '100%',
    '&, &:hover, &:focus': {
      color: 'inherit',
    },
  },
  linkMini: {
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)',
  },
});

const BrandBar = ({ logoText, isMinimizeButtonShown }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { isSidebarMiniModeActive, isSidebarHovered } = useSelector(
    state => state.SidebarStatus
  );

  const onMinimizeButtonClick = _e => {
    dispatch(
      changeSidebarStatus({
        isSidebarMiniModeActive: !isSidebarMiniModeActive,
      })
    );
  };

  return (
    <SidebarItem className={classes.sidebarItem}>
      {!isSidebarMiniModeActive || isSidebarHovered ? (
        <>
          <a
            href="/"
            className={classNames(classes.link, {
              [classes.linkMini]: isSidebarMiniModeActive && !isSidebarHovered,
            })}
          >
            {logoText}
          </a>
          {isMinimizeButtonShown && (
            <div className={classes.minimizeButtonWrapper}>
              <MinimizeButton
                isRotated={!isSidebarMiniModeActive}
                onClick={onMinimizeButtonClick}
              />
            </div>
          )}
        </>
      ) : (
        <div className={classes.minimizeButtonWrapperMini}>
          <MinimizeButton onClick={onMinimizeButtonClick} />
        </div>
      )}
    </SidebarItem>
  );
};

export default BrandBar;
