import { useTranslation } from 'react-i18next';
import { AddCircleOutline } from '@material-ui/icons';

import SidebarLink from '../../SidebarLink/SidebarLink';

const AdditionalOffer = () => {
  const { t } = useTranslation();

  return (
    <SidebarLink
      name={t('common.mainMenu.navLinks.additionalOffer')}
      to="/additional-offer"
      icon={<AddCircleOutline />}
    />
  );
};

export default AdditionalOffer;
