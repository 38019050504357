import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useHistory } from 'react-router-dom';
import navTabsStyle from 'assets/jss/material-dashboard-pro-react/components/navTabsStyle';
import { withTheme } from '@material-ui/core/styles';

const NavTabs = ({
  classes,
  tabs,
  changeTabOnMount,
  children,
  title,
  disabled = false,
}) => {
  const timeoutId = useRef(0);
  const [active, setActive] = useState(0);
  const history = useHistory();
  useEffect(() => {
    if (changeTabOnMount && changeTabOnMount.bool) {
      timeoutId.current = setTimeout(() => {
        setActive(changeTabOnMount.index);
      }, changeTabOnMount.timeout);
    }

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  const handleChange = (_, activeIndex) => {
    if (activeIndex === active) {
      return;
    }
    setActive(activeIndex);
    if (tabs[activeIndex].href) {
      history.replace(tabs[activeIndex].href);
    }
  };

  return (
    <>
      <h3 className={classes.tabsTitle}>{title}</h3>
      <div className={classes.tabsWrapper}>
        <Tabs
          classes={{
            root: classes.root,
            fixed: classes.fixed,
            flexContainer: classes.flexContainer,
            indicator: classes.displayNone,
          }}
          value={active}
          onChange={handleChange}
        >
          {tabs.map((prop, key) => {
            return (
              <Tab
                disabled={disabled}
                label={prop.tabButton}
                key={key}
                classes={{
                  root: classes.tabs,
                  labelContainer: classes.labelContainer,
                  label: classes.label,
                  selected: classes.selected,
                }}
              />
            );
          })}
        </Tabs>
        {children}
      </div>
      <div className={classes.contentWrapper}>
        {tabs.map((prop, key) => {
          if (key !== active) {
            return null;
          }
          return (
            <div className={classes.tabContent} key={key}>
              {prop.tabContent}
            </div>
          );
        })}
      </div>
    </>
  );
};

NavTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabContent: PropTypes.node,
    })
  ).isRequired,
  changeTabOnMount: PropTypes.shape({
    bool: PropTypes.bool,
    index: PropTypes.number,
    timeout: PropTypes.number,
  }),
  title: PropTypes.string,
};

export default withTheme()(withStyles(navTabsStyle)(NavTabs));
