import { useTranslation } from 'react-i18next';
import { TrendingUp } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const AdditionalOrders = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_SUB_BRAND) && {
      name: t('common.mainMenu.subBrands'),
      to: '/sub-brands',
      additionalActivationPaths: getPathSuffixes('/sub-brands', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_OVERPRODUCTION) && {
      name: t('common.mainMenu.newOrders'),
      to: '/overprod-planner',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.additionalOrders')}
      icon={<TrendingUp />}
      links={links}
    />
  );
};

export default AdditionalOrders;
