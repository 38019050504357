import { useTranslation } from 'react-i18next';
import { DeleteOutline } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const Waste = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_WASTE) && {
      name: t('common.mainMenu.addWaste'),
      to: '/add-waste',
    },
    isGranted(roles.ROLE_SHOW_REPORT_WASTE) && {
      name: t('common.mainMenu.wasteReport'),
      to: '/waste-report',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.waste')}
      icon={<DeleteOutline />}
      links={links}
    />
  );
};

export default Waste;
