import { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import BigCalendar from 'react-big-calendar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import { get } from 'helpers/apiHelpers';

import { dietEvent, noDietEvent, nonOperatingEvent } from './calendarEvents';
import { translateDietStatuses } from 'views/Orders/translateDietStatuses';
import BigCalendarHeaderSubscriptionButtons from './BigCalendarHeaderSubscriptionButtons';

class Calendar extends Component {
  state = {
    events: [],
    alert: null,
    disabledDays: [],
    isLoading: true,
    brandInfo: null,
    defaultCalendarMonth: null,
  };

  componentDidMount = async () => {
    await get(`/brands/${this.props.selectedBrand}`).then(brandInfo => {
      const defaultCalendarMonth = moment().isBetween(
        moment(this.props.dietInfo.firstDeliveryDate),
        moment(this.props.dietInfo.lastDeliveryDate)
      )
        ? new Date()
        : new Date(this.props.dietInfo.lastDeliveryDate);

      this.setState({
        brandInfo: brandInfo,
        disabledDays: brandInfo.disabledDays,
        defaultCalendarMonth,
      });

      this.props.setBrandInfo(brandInfo);
    });

    const startOfDefaultMonth = moment(this.state.defaultCalendarMonth)
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfDefaultMonth = moment(this.state.defaultCalendarMonth)
      .endOf('month')
      .format('YYYY-MM-DD');

    this.getCalendarDates(
      new moment(startOfDefaultMonth).format('YYYY-MM-DD'),
      new moment(endOfDefaultMonth).format('YYYY-MM-DD')
    );
  };

  componentDidUpdate = () => {
    if (this.props.calendarNeedsUpdate) {
      this.getCalendarDates(
        this.props.firstVisibleDay,
        this.props.lastVisibleDay
      );
      this.props.setCalendarNeedsUpdate(!this.props.calendarNeedsUpdate);
    }
  };

  //helpers
  hasBag = el => {
    return el.id !== null;
  };

  isManuallyDisabledDay = date => {
    return this.state.disabledDays.includes(
      moment(date).format('YYYY-MM-DD') + ' 00:00:00'
    );
  };

  isDisabledDay = date => {
    let key;
    key = new moment(date).locale('en').format('dddd').toLowerCase();

    return this.state.brandInfo[key].shouldBeDeliveredInBlock === 0;
  };
  //helpers end

  getCalendarDates = (firstVisibleDay, lastVisibleDay) => {
    const { t } = this.props;
    this.props.setBagInfo(null);
    get(
      `/ecommerce-diets/${this.props.match.params.id}/calendar/${firstVisibleDay}/${lastVisibleDay}`
    ).then(res => {
      const dietDetails = {
        ...res,
        days: [...Object.values(res.days)].map(el => {
          if (
            (this.isDisabledDay(el.date) ||
              this.isManuallyDisabledDay(el.date)) &&
            !this.hasBag(el)
          ) {
            return nonOperatingEvent(el.date, t);
          } else if (
            this.hasBag(el) &&
            el.newState !== 'NOT_DIET_CANT_PLACE_ORDER'
          ) {
            const infoBox = (
              <div>
                <p>{`ID: ${el.id}`}</p>
                <p>
                  <b>
                    {el.diet && el.diet.name}
                    <br />
                    {el.calorific && el.calorific.name}
                    <br />
                    {el.variant && el.variant.name}
                  </b>
                </p>
                {el.address ? (
                  <div>
                    <p>
                      {el.address.street}&nbsp;
                      {el.address.buildNumber}&nbsp;
                      <br />
                      {el.address.postCode}&nbsp;
                      <br />
                      {el.address.city}&nbsp;
                    </p>
                  </div>
                ) : (
                  <div>{el.pickUpPoint && el.pickUpPoint.value}</div>
                )}
              </div>
            );
            return dietEvent(el, infoBox);
          } else {
            return noDietEvent(el.date, t);
          }
        }),
      };
      this.setState({ events: dietDetails.days, isLoading: false });
    });
  };

  selectedEvent = event => {
    const selectedDate = moment(event.start).format('YYYY-MM-DD');
    const bagId = this.props.match.params.id;
    const isSelectedDaySubscription = event.type === 'SubscriptionIntent';

    if (event.id) {
      this.props.setIsSelectedDaySubscription(isSelectedDaySubscription);

      const requestUrl = isSelectedDaySubscription
        ? `/ecommerce-diets/${bagId}/delivery-days/${selectedDate}`
        : event.iri;

      return get(requestUrl).then(res => {
        const dietObject = this.props.diets.find(
          diet => diet['@id'] === res.diet?.['@id']
        );
        this.props.setFetchingDay(false);
        this.props.setBagInfo({ ...res, hasChanged: false });
        this.props.selectDiet(dietObject);
        this.props.setAddressType(res.address ? 'delivery' : 'pickup');
      });
    } else {
      this.props.setBagInfo(null);
    }
  };
  eventColors = (event, start, end, isSelected) => {
    let style;
    const { bagInfo } = this.props;

    const isSelectedDaySubscription =
      moment(event?.start).format('YYYY-MM-DD') ===
      moment(this.props?.bagInfo?.date).format('YYYY-MM-DD');

    const isIteratedDaySuspended = event.newState === 'SUBSCRIPTION_SUSPENDED';

    const isSelectedDayRegularDay = event.iri === this.props.bagInfo?.['@id'];

    const eventIsSelected = bagInfo
      ? isSelectedDayRegularDay || isSelectedDaySubscription
      : isSelected;

    const selectedStyle = {
      backgroundColor: '#9c27b0',
      color: '#fff',
      height: event.height,
    };

    const coloredStyle = {
      backgroundColor: event?.color,
      boxShadow: event.shadow,
      color: event.fontColor,
      height: event.height,
    };

    const suspendedStyle = {
      opacity: 0.5,
    };

    const defaultStyle = {
      backgroundColor: 'transparent',
      color: 'black',
    };

    eventIsSelected
      ? (style = selectedStyle)
      : event?.color
      ? (style = coloredStyle)
      : (style = defaultStyle);

    if (isIteratedDaySuspended) {
      style = { ...style, ...suspendedStyle };
    }

    return {
      style: style,
    };
  };

  render() {
    const { t } = this.props;
    const dietStatuses = translateDietStatuses(t);

    return (
      <>
        {this.state.defaultCalendarMonth && (
          <div>
            {this.state.alert}
            <Card>
              <CardBody calendar>
                <div
                  style={{
                    opacity: this.state.isLoading ? 0 : 1,
                    transition: '0.2s all',
                  }}
                >
                  <BigCalendarHeaderSubscriptionButtons
                    dietInfo={this.props.dietInfo}
                    fetchOnDietChange={this.props.fetchOnDietChange}
                  />
                  <BigCalendar
                    localizer={this.props.localizer}
                    messages={{
                      today: dietStatuses.NO_DIET,
                      previous: '<',
                      next: '>',
                    }}
                    events={this.state.events}
                    onRangeChange={view => {
                      const firstVisibleDate = new moment(view.start).format(
                        'YYYY-MM-DD'
                      );
                      const lastVisibleDate = new moment(view.end).format(
                        'YYYY-MM-DD'
                      );

                      this.props.setFirstVisibleDay(firstVisibleDate);
                      this.props.setLastVisibleDay(lastVisibleDate);
                      this.getCalendarDates(firstVisibleDate, lastVisibleDate);
                    }}
                    views={['month']}
                    defaultDate={this.state.defaultCalendarMonth}
                    tooltipAccessor={() => null}
                    onSelectEvent={event =>
                      !this.props.fetchingDay && this.selectedEvent(event)
                    }
                    eventPropGetter={this.eventColors}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedBrand: state.Auth.selectedBrand,
});

const enhance = compose(
  connect(mapStateToProps, null),
  withRouter,
  withStyles(buttonStyle),
  withTranslation()
);

export default enhance(Calendar);
