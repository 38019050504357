import types from 'helpers/constants';

const initialState = {
  isSidebarMiniModeActive: false,
  isSidebarHovered: false,
};

function SidebarStatus(state = initialState, { type, payload }) {
  switch (type) {
    case types.UPDATE_SIDEBAR_STATUS:
      return {
        ...state,
        ...payload,
      };
    case types.RESET_SIDEBAR_STATUS:
      return initialState;
    default:
      return state;
  }
}

export default SidebarStatus;
