import MealRoute from './MealRoute';
import GroupOfFinishedProductRoute from './GroupOfFinishedProductRoute';
import { useLocation } from 'react-router-dom';

const Meals = props => {
  const { pathname } = useLocation();
  const isKwg = pathname.includes('kwg-groups');

  return (
    <>
      {isKwg ? (
        <GroupOfFinishedProductRoute props={props} />
      ) : (
        <MealRoute props={props} />
      )}
    </>
  );
};

export default Meals;
