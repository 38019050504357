import { useTranslation } from 'react-i18next';

import { ReactComponent as Calculate } from 'assets/img/CalculateIcon.svg';

import SidebarLink from '../../SidebarLink/SidebarLink';

const Settlements = () => {
  const { t } = useTranslation();

  return (
    <SidebarLink
      name={t('common.mainMenu.settlements')}
      to="/settlements-settings"
      icon={<Calculate />}
    />
  );
};

export default Settlements;
