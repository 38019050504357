import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import { useTranslation } from 'react-i18next';

const ConfigurationPaymentMethodBreadcrumbAdditionalContent = () => {
  const { t } = useTranslation();

  return (
    <InfoWithTooltip
      title={t('common.mainMenu.paymentMethod')}
      link={EXTERNAL_URLS.paymentMethod}
      size="s"
    />
  );
};

export default ConfigurationPaymentMethodBreadcrumbAdditionalContent;
