import { useTranslation } from 'react-i18next';
import { Dashboard as DashboardIcon } from '@material-ui/icons';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const Dashboard = () => {
  const { t } = useTranslation();

  const links = [
    {
      name: t('common.mainMenu.dashboardStatistics'),
      to: '/dashboard',
    },
    {
      name: t('common.mainMenu.powerBIStatistics'),
      to: '/powerBIStatistics',
    },
  ];

  return (
    <SidebarLinks
      name={t('common.mainMenu.dashboard')}
      icon={<DashboardIcon />}
      links={links}
    />
  );
};

export default Dashboard;
