import { get, put } from 'helpers/apiHelpers';
import AdminTable from 'layouts/AdminTable';
import { useState } from 'react';
import { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import GridItem from 'components/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import SelectAll from 'components/SelectAll';
import DateTime from 'components/Form/DateTme';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/CustomButtons/Button';
import DataGrid from 'components/DataGrid/DataGrid';
import { columnConfig } from './columnConfig';
import { withToast } from 'material-ui-toast-redux';
import {
  CustomButton,
  CustomButtonAddIcon,
  StyledCustomButtonRemoveIcon,
} from './customButton';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import roles from 'helpers/roles';
import TOAST_DURATIONS from 'helpers/toastDurations';
const DishesPhotoGallery = ({ classes, openToast }) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [brands, setBrands] = useState([]);
  const [filter, setFilter] = useState({
    show: false,
    brand: [],
    dateFrom: new moment(),
    dateTo: new moment().add(1, 'day'),
  });

  const responsibleWidthDialogModal = useMediaQuery('(max-width:1100px)');
  const [queryData, setQueryData] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const data = [...rowData, ...queryData];
    setRowData(data);
  }, [queryData]);
  useEffect(() => {
    get('/brands').then(res => {
      setBrands(res['hydra:member']);
    });
  }, []);

  const updateFunction = dishes => {
    setIsEdit(true);

    put(`/dish-bags/${dishes.id}`, {
      clientImageIri: dishes.clientImageIri,
    })
      .then(res => {
        if (res?.clientImage) {
          return openToast({
            messages: [
              t(
                'common.dishesPhoto.dishes.add.success',
                'Zdjęcie zostało dodane'
              ),
            ],
            type: 'success',
            autoHideDuration: TOAST_DURATIONS.MD,
          });
        }
        return openToast({
          messages: [
            t(
              'common.dishesPhoto.dishes.delete.success',
              'Zdjęcie zostało usunięte'
            ),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.MD,
        });
      })
      .catch(err => {
        return openToast({
          messages: [
            t(
              'common.dishesPhoto.dishes.errorToast',
              'Błędne żądanie. Operacja nie została wykonana.'
            ),
          ],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.MD,
        });
      });
  };

  const handleSubmit = () => {
    if (!filter.brand.length) {
      return openToast({
        messages: [
          t(
            'common.dishesPhoto.filter.error',
            'Uzupełnij wszystkie wymagane pola'
          ),
        ],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.MD,
      });
    }
    setFilter(prev => {
      return {
        ...prev,
        show: true,
      };
    });
  };
  const customButton = <CustomButton />;
  const customButtonAddIcon = (previewUrl, handleClick) => {
    return (
      <CustomButtonAddIcon previewUrl={previewUrl} handleClick={handleClick} />
    );
  };

  const customButtonRemoveIcon = handleClick => {
    return <StyledCustomButtonRemoveIcon handleClick={handleClick} />;
  };
  return (
    <>
      <AdminTable title={t('dishes.gallery.title', 'Zdjęcia dań')}>
        <GridContainer
          justify="flex-end"
          style={{ flexWrap: 'wrap', alignItems: 'end' }}
        >
          <AdminTable>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('common.dishesPhoto.choiceBrand', 'Wybierz marki')} *
                </FormLabel>
                <SelectAll
                  className="input-select--brand"
                  options={brands}
                  trackBy={'@id'}
                  mapBy={'name'}
                  optionSelected={filter.brand}
                  handleChange={e => {
                    setRowData([]);
                    setFilter(prev => {
                      return { ...prev, brand: e, show: false };
                    });
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={4} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '3px' }}
                >
                  {t('common.dishesPhoto.dateFrom', 'Data dostarczenia od')} *
                </FormLabel>
                <FormControl fullWidth>
                  <DateTime
                    className="input-datetime--date-from"
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(filter.dateFrom)}
                    onChange={ev => {
                      setRowData([]);
                      setFilter(prev => {
                        return { ...prev, dateFrom: ev, show: false };
                      });
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={6} md={4} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '3px' }}
                >
                  {t('common.dishesPhoto.dateTo', 'Data dostarczenia do')} *
                </FormLabel>
                <FormControl fullWidth>
                  <DateTime
                    className="input-datetime--date-from"
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(filter.dateTo)}
                    onChange={ev => {
                      setRowData([]);
                      setFilter(prev => {
                        return { ...prev, dateTo: ev, show: false };
                      });
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </AdminTable>
          <GridItem xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              color={'success'}
              style={{ borderRadius: '30px' }}
              onClick={handleSubmit}
            >
              {t('common.dishesPhoto.showDishes', 'Pokaż dania')}
            </Button>
          </GridItem>
        </GridContainer>
      </AdminTable>

      {filter.show && (
        <AdminTable>
          <DataGrid
            actions={true}
            url={`/dish-bags`}
            defaultHiddenColumns={window.innerWidth < 540 && ['nameForClient']}
            columns={columnConfig(
              t,
              classes,
              updateFunction,
              customButton,
              customButtonAddIcon,
              customButtonRemoveIcon,
              isEdit,
              setIsEdit,
              responsibleWidthDialogModal,
              rowData
            )}
            role={roles.ROLE_SHOW_DISH_PHOTO_GALLERY}
            manipulateQuery={(requestData, query) => {
              setQueryData(requestData.data);

              query['pagination'] = true;
              query['dateFrom'] = filter.dateFrom.format('YYYY-MM-DD');
              query['dateTo'] = filter.dateTo.format('YYYY-MM-DD');
              const brands = filter.brand.map(el => el.value);

              query['brands'] = brands;
              return query;
            }}
          />
        </AdminTable>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withToast);
export default enhance(DishesPhotoGallery);
