import { useTranslation } from 'react-i18next';
import { CheckBox } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const CateringsFlow = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_TASKS_GENERATOR) && {
      name: t('common.mainMenu.tasksGenerator'),
      to: '/tasks-generator',
    },
    isGranted(roles.ROLE_SHOW_KITCHEN) && {
      name: t('common.mainMenu.kitchen'),
      to: '/kitchen',
      additionalActivationPaths: getPathSuffixes('/kitchen', ['preview/:id']),
    },
    isGranted(roles.ROLE_SHOW_DISH_PACKHOUSE) && {
      name: t('common.mainMenu.dishesPackhouse'),
      to: '/dishes-packhouse',
      additionalActivationPaths: getPathSuffixes('/dishes-packhouse', [
        'preview/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_BAGS_PACKHOUSE) && {
      name: t('common.mainMenu.bagsPackhouse'),
      to: '/bags-packhouse',
      additionalActivationPaths: getPathSuffixes('/bags-packhouse', [
        'preview/:id',
      ]),
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.cateringsFlow')}
      icon={<CheckBox />}
      links={links}
    />
  );
};

export default CateringsFlow;
