import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import Dashboard from './Dashboard/Dashboard';
import Dictionaries from './Dictionaries/Dictionaries';
import CompanyOffer from './CompanyOffer/CompanyOffer';
import RecipeDatabase from './RecipeDatabase/RecipeDatabase';
import AdditionalOrders from './AdditionalOrders/AdditionalOrders';
import Waste from './Waste/Waste';
import PriceLists from './PriceLists/PriceLists';
import Addons from './Addons/Addons';
import Announcements from './Announcements/Announcements';
import DiscountCodes from './DiscountCodes/DiscountCodes';
import CustomerService from './CustomerService/CustomerService';
import KasiaModule from './KasiaModule/KasiaModule';
import Logistics from './Logistics/Logistics';
import MenuPlanning from './MenuPlanning/MenuPlanning';
import ProductionReports from './ProductionReports/ProductionReports';
import AnalyticalReports from './AnalyticalReports/AnalyticalReports';
import AccountingReports from './AccountingReports/AccountingReports';
import CateringsFlow from './CateringsFlow/CateringsFlow';
import MyCompany from './MyCompany/MyCompany';
import ConfigureBrand from './ConfigureBrand/ConfigureBrand';
import Settlements from './Settlements/Settlements';
import Cms from './Cms/Cms';
import Translations from './Translations/Translations';
import Integrations from './Integrations/Integrations';
import AdditionalOffer from './AdditionalOffer/AdditionalOffer';

const Links = () => (
  <>
    <Dashboard />
    {isGranted(roles.ROLE_SHOW_DICTIONARY) && <Dictionaries />}
    <CompanyOffer />
    <RecipeDatabase />
    <MenuPlanning />
    <AdditionalOrders />
    {isGranted(roles.ROLE_SHOW_OVERPRODUCTION) && <Waste />}
    <PriceLists />
    {isGranted(roles.ROLE_SHOW_ADDON) && <Addons />}
    {isGranted(roles.ROLE_SHOW_NOTIFICATION) && <Announcements />}
    {isGranted(roles.ROLE_SHOW_DISCOUNT_CODE) && <DiscountCodes />}
    <CustomerService />
    {isGranted(roles.ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT) && (
      <KasiaModule />
    )}
    <Logistics />
    <ProductionReports />
    <AnalyticalReports />
    <AccountingReports />
    <CateringsFlow />
    <MyCompany />
    <ConfigureBrand />
    {isGranted(roles.ROLE_SHOW_MODULE_SETTLEMENTS) && <Settlements />}
    <Cms />
    <Translations />
    {isGranted(roles.ROLE_SHOW_BRAND) && <Integrations />}
    {isGranted(roles.ROLE_SHOW_BRAND) && <AdditionalOffer />}
  </>
);

export default Links;
