import YesNoFilter from 'components/Grid/Filter/YesNo';
import { Tooltip } from '@material-ui/core';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
import { Link } from 'react-router-dom';
import {
  paymentTypeOptions,
  paymentTypesTranslations,
} from '../Orders/Orders/columnConfig';
import { translateOrderStatuses } from '../Orders/Orders/statusTranslations';

const STATUS_COLOR = {
  PAID: { color: '#fff', background: '#4CAF50' },
  CANCELED: { color: '#fff', background: '#F44336' },
  WAITING_FOR_PAYMENT: { color: '#fff', background: '#FB8C00' },
};

const columnConfig = t => [
  {
    title: 'ID',
    sortable: false,
    accessor: 'id',
    name: 'id',
  },
  {
    title: 'subscription.clientDiet.client',
    sortable: false,
    accessor: row =>
      row.client && (
        <Link to={`/admin/clients/edit/${row.client.id}`} target="_blank">
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {row.client.firstName} {row.client.lastName}
          </span>
        </Link>
      ),
    name: 'client',
  },
  {
    title: 'clients.idorder',
    accessor: 'placedOrder.id',
    name: 'placedOrder.id',
    filterable: true,
    sortable: true,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.order.status',
    accessor: row => {
      if (!row.placedOrder?.status?.value) {
        return '';
      } else {
        return (
          <>
            <div
              style={{
                padding: '6px',
                borderRadius: '5px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#dedede',
                color:
                  STATUS_COLOR[row.placedOrder?.status?.systemValue].color ??
                  '#303030',
                backgroundColor:
                  STATUS_COLOR[row.placedOrder?.status?.systemValue]
                    .background ?? 'transparent',
              }}
            >
              {row.placedOrder?.status?.value}
            </div>
          </>
        );
      }
    },
    filterable: true,
    sortable: false,
    name: 'placedOrder.status.systemValue',
    Filter: ({ onChange, filter }) => {
      const selectOptions = translateOrderStatuses(t);
      return (
        <select
          style={{ height: '30px', width: '100%', padding: '0' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value={''}>{selectOptions[0].label}</option>
          <option value={'EMPTY'}>{t('basket.details.noOrder')}</option>
          <option value={selectOptions[1].systemValue}>
            {selectOptions[1].label}
          </option>
          <option value={selectOptions[2].systemValue}>
            {selectOptions[2].label}
          </option>
          <option value={selectOptions[3].systemValue}>
            {selectOptions[3].label}
          </option>
        </select>
      );
    },
  },
  {
    title: 'basket.rows',
    name: 'rowTypes',
    sortable: false,
    filterable: false,
    accessor: row =>
      (row?.rowTypes ?? []).map(el => (
        <>
          <span
            style={{
              display: 'block',
              float: 'left',
              padding: '6px',
              margin: '2px',
              borderRadius: '5px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#dedede',
              color: '#303030',
              backgroundColor: 'transparent',
            }}
          >
            {el.label}
          </span>
        </>
      )),
  },
  {
    title: 'basket.price',
    name: 'price',
    width: 100,
    Filter: ({ onChange, filter }) => (
      <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
    ),
    sortable: false,
    filterable: false,
    accessor: row => (
      <div style={{ textAlign: 'right', paddingRight: 8 }}>
        <Tooltip
          interactive
          title={
            <div style={{ fontSize: '12px' }}>
              <table className={'table'}>
                <thead>
                  <tr>
                    <th>{t('subscription.priceBeforeDiscount')}</th>
                    <th>{t('subscription.priceDiscount')}</th>
                    <th>{t('subscription.priceAfterDiscount')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{row.price.beforeDiscount.toFixed(2)}</td>
                    <td>{row.price.discount.toFixed(2)}</td>
                    <td>{row.price.afterDiscount.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              {Object.keys(row.price.priceParts).length > 0 && (
                <>
                  <hr />
                  <span>{t('basket.priceDetails.costInfo')}:</span>
                  <table>
                    <tbody>
                      {Object.keys(row.price.priceParts).map((el, index) => (
                        <tr key={index}>
                          <th style={{ textAlign: 'left' }}>
                            {t(`basket.priceDetails.costInfo.${el}`)}
                          </th>
                          <th style={{ textAlign: 'left' }}>
                            {row.price.priceParts[el].toFixed(2)}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {Object.keys(row.price.discountParts).length > 0 && (
                <>
                  <hr />
                  <span>{t('basket.priceDetails.discountInfo')}:</span>
                  <table>
                    <tbody>
                      {Object.keys(row.price.discountParts).map((el, index) => (
                        <tr key={index}>
                          <th style={{ textAlign: 'left' }}>
                            {t(`basket.priceDetails.discountInfo.${el}`)}
                          </th>
                          <th style={{ textAlign: 'left' }}>
                            {row.price.discountParts[el].toFixed(2)}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          }
          placement={'right'}
        >
          <span style={{ cursor: 'help' }}>
            {row.price.afterDiscount.toFixed(2)}
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'discountTypes.code',
    accessor: 'discountCode.code',
    name: 'discountCode.code',
    filterable: true,
    sortable: true,
  },
  {
    title: 'basket.abandoned',
    accessor: row => <BooleanView value={row.abandoned} />,
    name: 'abandoned',
    width: 75,
    Filter: ({ onChange, filter }) => {
      return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
    },
    filterable: true,
    sortable: false,
  },
  {
    title: 'clients.moneybox',
    accessor: row => <BooleanView value={row.useMoneybox} />,
    name: 'useMoneybox',
    width: 75,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    filterable: true,
    sortable: false,
  },
  {
    title: 'orders.isTest',
    accessor: row => <BooleanView value={row.testMode} />,
    name: 'testMode',
    width: 75,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    filterable: true,
    sortable: false,
  },
  {
    title: 'orders.paymentType',
    accessor: row => t(paymentTypesTranslations[row.paymentType]),
    sortable: false,
    filterable: true,
    name: 'paymentType',
    Filter: ({ onChange, filter }) => (
      <select
        style={{ height: '30px', width: '100%', padding: '0' }}
        onChange={e => onChange(e.target.value)}
        value={filter?.value || ''}
      >
        <option value={''}>{t(paymentTypeOptions[0])}</option>
        {Object.entries(paymentTypesTranslations).map(([key, value]) => (
          <option value={key}>{t(value)}</option>
        ))}
      </select>
    ),
  },
  {
    title: 'clients.creationDate',
    Filter: ({ onChange, filter }) => (
      <DateRangeFilter onChange={onChange} value={filter?.value} />
    ),
    accessor: row => dateTimeCell(row.createdAt),
    name: 'createdAt',
    filterable: true,
    sortable: true,
    width: 160,
  },
];

export default columnConfig;
