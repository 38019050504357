import { useTranslation } from 'react-i18next';
import { AccountBalance } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const AccountingReports = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_REPORT_ACCOUNTANT_INCOME) && {
      name: t('reports.accountant.income'),
      to: '/income-accountant-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS) && {
      name: t('reports.accountant.depositAndRefunds'),
      to: '/deposit-and-refunds-accountant-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_ACCOUNTANT_NEW_ACCOUNTS) && {
      name: t('reports.accountant.newAccounts'),
      to: '/new-accounts-accountant-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_DISPOSAL) && {
      name: t('common.mainMenu.reportsDisposal'),
      to: '/disposal-accountant-report',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('reports.accountant')}
      icon={<AccountBalance />}
      links={links}
    />
  );
};

export default AccountingReports;
