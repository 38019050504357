import { cloneDeep } from 'lodash';
import { store } from '../store';

export const createSeparatedItems = arr => arr.join(', ');

export const getValuesFromKey = (array, key, deepKey = null) =>
  array.map(item => (deepKey ? item[key][deepKey] : item[key]));

export const trimEmptyStringsFromQuery = values =>
  values.filter(value => value.length > 0);

export const trimSpaces = object => {
  const type = typeof object;

  if (type === 'string') {
    return object.replace(/\s+/g, ' ').trim();
  }

  if (Array.isArray(object)) {
    return [...object.map(item => trimSpaces(item))];
  }

  if (type === 'function' || (type === 'object' && !!object)) {
    return Object.entries(object).reduce((acc, [key, value]) => {
      return { ...acc, [key]: trimSpaces(value) };
    }, {});
  }

  return object;
};

export const sortBy = (collection, sortProperty) =>
  collection.sort((a, b) => a[sortProperty] - b[sortProperty]);
export const sortByPosition = collection => sortBy(collection, 'position');
export const sortByGroupedBrandPosition = collection => {
  const collectionGoupedByBrand = collection
    .filter(element => !isEmpty(element))
    .reduce((acc, item) => {
      const {
        brand: { '@id': brandId },
      } = item;

      return {
        ...acc,
        [brandId]:
          acc[brandId] === undefined ? [item] : [...acc[brandId], item],
      };
    }, {});

  const collectionBrandPosition = Object.values(collectionGoupedByBrand)
    .map(brandCollection => sortByPosition(brandCollection))
    .flat();

  return collectionBrandPosition;
};

export const sortByKey = (array, key) => {
  return cloneDeep(array).sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const sortByNestedKey = (array, key, nestedKey) => {
  return cloneDeep(array).sort((a, b) => {
    const x = a[key][nestedKey];
    const y = b[key][nestedKey];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map(arg => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

export const isGranted = roleOrRoles => {
  const roles = store.getState().Auth.user.roles || [];

  return Array.isArray(roleOrRoles)
    ? roleOrRoles.every(role => roles.includes(role))
    : roles.includes(roleOrRoles);
};

export const getNumberFromString = string => string.replace(/^\D+/g, '');
export const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const translateNamesForExport = t => ({
  '/admin-nav-links': t('namesForExport.navLinks', 'skróty_'),
  '/clients': t('namesForExport.clients', 'klienci_'),
  '/orders': t('namesForExport.orders', 'zamówienia_'),
  '/ecommerce-diets': t('namesForExport.ecommerceDiets', 'diety_'),
  '/ecommerce-bags-materialized': t('ecommerceBags.name', 'diety_'),
  '/rates': t('namesForExport.rates', 'komentarze_'),
  '/zones': t('namesForExport.zones', 'strefy_'),
  '/zone-categories': t('namesForExport.zoneCategories', 'kategorie_stref_'),
  '/zone-post-codes-export': t(
    'namesForExport.zonePstCodes',
    'strefy_kody_pocztowe_'
  ),
  '/addresses': t('namesForExport.logisticAddress', 'adresy_'),
  '/drivers': t('namesForExport.drivers', 'kierowcy_'),
  'pick-up-points': t('namesForExport.pickUpPoints', 'punkty_odbioru_'),
  '/pages': t('namesForExport.subPagesCreator', 'kreator_podstron'),
  '/messages': t('namesForExport.messages', 'komunikaty_'),
  ingredients: t('namesForExport.ingredients', 'składniki_'),
  '/recipes': t('namesForExport.recipes', 'przepisy_'),
  '/dishes': t('namesForExport.dishes', 'dania_'),
  sizes: t('namesForExport.sizes', 'wielkości_'),
  'meal-types': t('namesForExport.mealTypes', 'typy_'),
  diets: t('namesForExport.diets', 'diety_'),
  packages: t('namesForExport.packages', 'filtry_'),
  variants: t('namesForExport.variants', 'warianty_'),
  allergens: t('namesForExport.allergens', 'alergeny_'),
  wholesalers: t('namesForExport.wholesalers', 'hurtownicy_'),
  units: t('namesForExport.units', 'jednostki_'),
  'ingredient-categories': t(
    'namesForExport.ingredientsCategories',
    'kategorie_składników_'
  ),
  containers: t('namesForExport.containers', 'opakowania_'),
  'eco-containers': t('namesForExport.ecoContainers', 'eko_opakowania_'),
  'serving-suggestions': t(
    'namesForExport.servingsSuggestions',
    'propozycje_podania_'
  ),
  'client-tags': t('namesForExport.clients.tags', 'tagi_dla_klientów_'),
  tags: t('namesForExport.tags', 'tagi_dla_produkcji_'),
  'delivery-types': t('namesForExport.deliveryTypes', 'typy_dostawy_'),
  'diet-costs': t('namesForExport.dietCosts', 'cenniki_diety_'),
  'zone-costs': t('namesForExport.zoneCosts', 'cenniki_dostawy_'),
  '/address-requests': t(
    'namesForExport.addressRequests',
    'zapytania_klientów'
  ),
});

// translate
export const errorNotificationMapObject = {
  plainPassword: 'Hasło',
  email: 'Email',
  phone: 'Telefon',
};

export const slugify = string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const loadFontToDocumentHead = url => {
  var head = document.getElementsByTagName('head')[0];
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  head.appendChild(link);
  link.href = url;
};

export const countBeforeDecimal = number =>
  number?.toString()?.split('.')?.[0]?.length || 0;

export const countDecimal = number => {
  if (Math.floor(number.valueOf()) === number.valueOf()) {
    return 0;
  }

  return number?.toString()?.split('.')?.[1]?.length || 0;
};

export const toDecimalPlaces = (number, places = 2) => {
  const [int, decimal = 0] = number?.toString()?.split('.');
  if (decimal !== 0) {
    const substringDecimal = decimal.substring(0, places);

    return `${int}.${substringDecimal}`;
  } else {
    return int;
  }
};

export const getIntegerIfIntegerOrFixed = (
  number,
  decimalNumber = 2,
  defaultValue = ''
) => {
  if ([null, undefined, ''].includes(number)) {
    return defaultValue;
  }

  return Number.isInteger(number) ? number : number.toFixed(decimalNumber);
};

export const postCodeValidator = input => {
  const regexp =
    store.getState().Brands.selectedRegionForValidations.postCodeRegexp;

  if (null === regexp) {
    return true;
  }

  return input?.match(`${regexp}$`);
};

export const copyToClipboard = (text, onSuccess) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(textarea);
  onSuccess();
};

export const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false; // the string was not a valid URL
  }
};
