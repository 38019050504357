import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { List } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTheme } from '@mui/material';

import Links from './Links/Links';
import {
  drawerMiniWidth,
  drawerWidth,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles({
  container: ({ theme }) => ({
    position: 'relative',
    height: 'calc(100vh - 235px)',
    zIndex: '4',
    width: `${drawerWidth}px`,
    overflow: 'hidden auto',
    overflowScrolling: 'touch',
    transition: 'top 0.2s linear, bottom 0.2s linear',
    fontSize: '12px',
    color: 'inherit',
    '&::-webkit-scrollbar': {
      width: '9px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: grayColor[11],
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 100px)',
    },
  }),
  sidebarMini: ({ theme }) => ({
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: `${drawerMiniWidth}px`,
    },
  }),
  list: {
    display: 'grid',
    rowGap: '5px',
    maxWidth: '241px',
    margin: '5px 15px',
    padding: '0',
  },
  listMini: {
    maxWidth: '50px',
    margin: '5px auto',
    padding: '0',
  },
});

const LinksBar = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { isSidebarMiniModeActive, isSidebarHovered } = useSelector(
    state => state.SidebarStatus
  );

  return (
    <div
      className={classNames(classes.container, {
        [classes.sidebarMini]: isSidebarMiniModeActive && !isSidebarHovered,
      })}
    >
      <List
        className={classNames(classes.list, {
          [classes.listMini]: isSidebarMiniModeActive && !isSidebarHovered,
        })}
      >
        <Links />
      </List>
    </div>
  );
};

export default LinksBar;
