import { Router, Route, Switch } from 'react-router';

import BrandInformation from 'views/BrandConfiguration/BrandInformation';

const ActiveBrandInformation = ({ history, match }) => (
  <Router history={history}>
    <Switch>
      <Route
        path={`${match.url}/`}
        render={() => <BrandInformation isEdit />}
      />
    </Switch>
  </Router>
);

export default ActiveBrandInformation;
