import { useTranslation } from 'react-i18next';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { useState } from 'react';
import { useInterval } from 'react-use';
import { intervalToDuration } from 'date-fns';

import withStyles from '@material-ui/core/styles/withStyles';

const countdownStyle = {
  countdownWrapper: {
    display: 'flex',
  },
  timeUnit: {
    margin: '0 1px',
  },
};

const Countdown = ({
  classes,
  startDate = new Date(),
  endDate,
  interval = 1000,
  disableYears = true,
  disableMonths = true,
  disableDays = false,
  disableHours = false,
  disableMinutes = false,
  disableSeconds = false,
  showEmptyPartialTime = false,
  onCountdownTimeout = () => {},
}) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState({});

  const startDateInstance = new Date(startDate);
  const endDateInstance = new Date(endDate);

  useInterval(
    () => {
      if (!moment(new Date()).isBefore(moment(new Date(endDate)))) {
        return onCountdownTimeout();
      }
      const intevalDuration = intervalToDuration({
        start: startDateInstance,
        end: endDateInstance,
      });

      setDuration(intevalDuration);
    },
    isEmpty(duration) ? 0 : interval
  );

  return (
    <div className={classes.countdownWrapper}>
      {!disableYears && (duration.years > 0 || showEmptyPartialTime) && (
        <div className={classes.timeUnit}>
          {duration.years} {t('$*common.years.short', '$$l.')}
        </div>
      )}
      {!disableMonths && (duration.months > 0 || showEmptyPartialTime) && (
        <div className={classes.timeUnit}>
          {duration.months} {t('$*common.months.short', '$$mies.')}
        </div>
      )}
      {!disableDays &&
        (duration.days > 0 || duration.months > 0 || showEmptyPartialTime) && (
          <div className={classes.timeUnit}>
            {duration.days} {t('$*common.days.short', '$$d')}
          </div>
        )}
      {!disableHours &&
        (duration.hours > 0 || duration.days > 0 || showEmptyPartialTime) && (
          <div className={classes.timeUnit}>
            {duration.hours} {t('$*common.hours.short', '$$g.')}
          </div>
        )}
      {!disableMinutes &&
        (duration.minutes > 0 ||
          duration.hours > 0 ||
          showEmptyPartialTime) && (
          <div className={classes.timeUnit}>
            {duration.minutes} {t('$*common.minutes.short', '$$min.')}
          </div>
        )}
      {!disableSeconds &&
        (duration.seconds > 0 ||
          duration.minutes > 0 ||
          showEmptyPartialTime) && (
          <div className={classes.timeUnit}>
            {duration.seconds} {t('$*common.seconds.short', '$$s')}
          </div>
        )}
    </div>
  );
};

export default withStyles(countdownStyle)(Countdown);
