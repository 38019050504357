import { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import routes from 'routes';
import loginFormImg from '../assets/img/login-form.png';
import { store } from '../store';
import S from '../views/Pages/AuthStyled';
import {
  blackColor,
  hexToRgb,
  whiteColor,
} from '../assets/jss/material-dashboard-pro-react';

const pagesStyle = theme => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0',
  },
  fullPage: {
    padding: '120px 0',
    position: 'relative',
    minHeight: '100vh',
    display: 'flex!important',
    margin: '0',
    border: '0',
    color: whiteColor,
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'fit-content!important',
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      border: 'none !important',
    },
    '&:before': {
      backgroundColor: 'rgba(' + hexToRgb(blackColor) + ', 0.65)',
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '2',
    },
  },
});

class Pages extends Component {
  componentDidMount() {
    store.dispatch({
      type: 'LOGOUT',
    });
    document.body.style.overflow = 'unset';
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = routes => {
    let activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i]?.nameFallback ?? routes[i]?.name;
        }
      }
    }
    return activeRoute;
  };
  setDocTitle = () => {
    document.title = this.getActiveRoute(routes);
  };
  render() {
    this.setDocTitle();
    const hasToken = store.getState().Auth.token;
    const hasRoles = store.getState().Roles.roles.length > 0;

    if (hasToken && hasRoles) {
      return <Redirect to="/admin/dashboard" />;
    }

    return (
      <S.Background img={loginFormImg}>
        <S.Wrapper>
          <S.LeftColumn>
            <img src={loginFormImg} alt="" />
          </S.LeftColumn>

          <S.RightColumn>
            <Switch>{this.getRoutes(routes)}</Switch>
          </S.RightColumn>
        </S.Wrapper>
      </S.Background>
    );
  }
}

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(withStyles(pagesStyle)(Pages));
