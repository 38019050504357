import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import SidebarLinkIcon from '../SidebarLinkIcon/SidebarLinkIcon';
import NavLinkBase from '../NavLinkBase/NavLinkBase';

const useStyles = makeStyles({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 10px 6px 13px',
  },
  navLinkMini: {
    justifyContent: 'center',
  },
  name: {
    margin: '0 12px 0 18px',
    fontWeight: '400',
    wordBreak: 'break-word',
  },
});

const SidebarLink = ({ name, to, icon, additionalActivationPaths = [] }) => {
  const classes = useStyles();

  const { isSidebarMiniModeActive, isSidebarHovered } = useSelector(
    state => state.SidebarStatus
  );

  return (
    <NavLinkBase
      className={classNames(classes.navLink, {
        [classes.navLinkMini]: isSidebarMiniModeActive && !isSidebarHovered,
      })}
      to={to}
      additionalActivationPaths={additionalActivationPaths}
    >
      <SidebarLinkIcon icon={icon} />
      {(!isSidebarMiniModeActive || isSidebarHovered) && (
        <span className={classes.name}>{name}</span>
      )}
    </NavLinkBase>
  );
};

SidebarLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  additionalActivationPaths: PropTypes.arrayOf(PropTypes.string),
};

export default SidebarLink;
