import { reducer as toast } from 'material-ui-toast-redux';
import { combineReducers } from 'redux';

import Addresses from './Addresses';
import Allergens from './Allergens';
import App from './App';
import Auth from './Auth';
import Brands from './Brands';
import Company from './Company';
import Containers from './Containers';
import DeliveryTypes from './DeliveryTypes';
import Departments from './Departments';
import Dictionary from './Dictionary';
import Diets from './Diets';
import Dishes from './Dishes';
import Drivers from './Drivers';
import Employees from './Employees';
import Ingredients from './Ingredients';
import Kitchens from './Kitchens';
import MealSizes from './MealSizes';
import MealTypes from './MealTypes';
import MenuPlanners from './MenuPlanners';
import MenuTemplates from './MenuTemplates';
import OrderStatuses from './OrderStatuses';
import Packages from './Packages';
import Permissions from './Permissions';
import Purposes from './Purposes';
import Recipes from './Recipes';
import Roles from './Roles';
import SidebarStatus from './SidebarStatus';
import SubBrands from './SubBrands';
import Translatable from './Translatable';
import Units from './Units';
import Users from './Users';
import Variants from './Variants';
import Wholesalers from './Wholesalers';
import ZoneCategories from './ZoneCategories';
import Zones from './Zones';

export default combineReducers({
  Addresses,
  Allergens,
  App,
  Auth,
  Brands,
  Company,
  Containers,
  Departments,
  Kitchens,
  DeliveryTypes,
  Dictionary,
  Diets,
  Dishes,
  Drivers,
  Employees,
  Ingredients,
  MealSizes,
  MealTypes,
  MenuPlanners,
  MenuTemplates,
  OrderStatuses,
  Packages,
  Permissions,
  Purposes,
  Recipes,
  Roles,
  SubBrands,
  Translatable,
  toast,
  Units,
  Users,
  Variants,
  Wholesalers,
  ZoneCategories,
  Zones,
  SidebarStatus,
});
