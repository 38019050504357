import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import { copyToClipboard } from 'helpers/helpers';
import { withToast } from 'material-ui-toast-redux';
import { compose } from 'redux';
import { FileCopyOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import TOAST_DURATIONS from 'helpers/toastDurations';

const ClientsBmi = ({ tableTitle, openToast }) => {
  const { t } = useTranslation();

  const columnConfig = [
    {
      title: 'columns.id',
      name: 'ids',
      sortable: false,
      filterable: false,
      accessor: ({ id }) => (
        <span
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            cursor: 'default',
          }}
        >
          <Tooltip title={id}>
            <span>{id.slice(0, 4) + '...' + id.slice(-6)}</span>
          </Tooltip>
          <FileCopyOutlined
            style={{
              color: 'RGB(44,186,204)',
              marginLeft: '0.2em',
              cursor: 'pointer',
              width: '1rem',
              height: '1rem',
            }}
            onClick={() => {
              copyToClipboard(id, () => {
                openToast({
                  messages: [t('common.id.copyClipboard.success')],
                  type: 'success',
                  autoHideDuration: TOAST_DURATIONS.MD,
                });
              });
            }}
          />
        </span>
      ),
    },
    {
      title: 'columns.clientId',
      name: 'client.id',
      sortable: false,
      filterable: true,
      accessor: row => {
        const id = row.client.match(/\/clients\/(\d+)/)[1];
        return (
          <Link target="_blank" to={`/admin/clients/edit/${id}`}>
            {id}
          </Link>
        );
      },
      width: 100,
    },
    {
      title: t('columns.date'),
      name: 'createdAt',
      Filter: ({ onChange, filter }) => (
        <DateRangeFilter onChange={onChange} value={filter?.value} />
      ),
      accessor: row => dateTimeCell(row.createdAt),
      width: 180,
    },
    {
      title: t('clients.gender'),
      name: 'gender',
      accessor: ({ gender }) => {
        switch (gender) {
          case 'male':
            return t('clients.man');
          case 'female':
            return t('clients.woman');
          default:
            return '';
        }
      },
      Filter: ({ onChange, filter }) => (
        <select
          style={{ height: '30px', width: '100%', padding: '0' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value="">{t('common.all')}</option>
          <option value="male">{t('clients.man')}</option>
          <option value="female">{t('clients.woman')}</option>
        </select>
      ),
    },
    {
      title: t('columns.age'),
      name: 'age',
      accessor: 'age',
    },
    {
      title: t('columns.height'),
      name: 'height',
      accessor: 'height',
    },
    {
      title: t('columns.weight'),
      name: 'weight',
      accessor: 'weight',
    },
    {
      title: t('columns.bmi'),
      name: 'bmi',
      accessor: 'bmi',
    },
  ];

  return (
    <AdminTable title={t(tableTitle)}>
      <DataGrid
        remove={true}
        actions={true}
        paginationTop={true}
        paginationBottom={false}
        url="/client-metrics"
        columns={columnConfig}
        role="CLIENT_METRIC"
        defaultSorted={[{ id: 'createdAt', desc: true }]}
        minRows={20}
        customActionsWidth={50}
        manipulateQuery={(_, query) => {
          if (query.createdAt) {
            const filters = query.createdAt;
            delete query.createdAt;
            if (filters.after) {
              query['createdAt[after]'] = filters.after;
            }
            if (filters.before) {
              query['createdAt[before]'] = filters.before;
            }
          }

          return query;
        }}
      />
    </AdminTable>
  );
};

const enhance = compose(withToast, withRouter);

export default enhance(ClientsBmi);
