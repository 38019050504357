import Moment from 'moment';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import DateFilter from 'components/Grid/Filter/Date';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const translateColumnConfig = ({ t, handleOpenNotes }) => [
  {
    title: 'ID',
    name: 'id',
    accessor: 'id',
    width: 75,
  },
  {
    title: 'diets.orderId',
    accessor: 'order.id',
    width: 100,
    name: 'order.id',
  },
  {
    title: 'diets.createdAt',
    name: 'createdAt',
    accessor: row => new Moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    Filter: ({ onChange, filter }) => (
      <DateRangeFilter onChange={onChange} value={filter?.value} />
    ),
    width: 160,
  },
  {
    title: 'diets.isFirstOrderDiet',
    name: 'order.isClientsFirstOrder',
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.order.isClientsFirstOrder} />,
    sortable: false,
    filterable: true,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'diets.active',
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.active} />,
    name: 'active',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: true,
  },
  {
    title: 'diets.isSubscription',
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.isSubscription} />,
    name: 'isSubscription',
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'diets.firstDeliveryDay',
    accessor: row =>
      row.firstDeliveryDate
        ? new Moment(row.firstDeliveryDate).format('YYYY-MM-DD')
        : '-',
    Filter: ({ onChange, filter }) => (
      <DateFilter onChange={onChange} value={filter?.value} />
    ),
    name: 'firstDeliveryDate',
    width: 100,
    sortable: true,
    filterable: true,
  },
  {
    title: 'diets.lastDeliveryDay',
    accessor: row =>
      row.lastDeliveryDate
        ? new Moment(row.lastDeliveryDate).format('YYYY-MM-DD')
        : '-',
    Filter: ({ onChange, filter }) => (
      <DateFilter onChange={onChange} value={filter?.value} />
    ),
    name: 'lastDeliveryDate',
    width: 100,
    sortable: true,
    filterable: true,
  },
  {
    title: 'diets.orderDays',
    accessor: 'originalDietLength',
    name: 'originalDietLength',
    width: 75,
    filterable: true,
    sortable: true,
  },
  {
    title: 'diets.hasNextDiet',
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.hasNextDiet} />,
    name: 'hasNextDiet',
    width: 100,
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'diets.fullname',
    accessor: row =>
      row?.client && (
        <Link to={`/admin/clients/edit/${row.client.id}`} target="_blank">
          {`${row.client.firstName} ${row.client.lastName}`}
        </Link>
      ),

    name: 'client.firstName',
  },
  {
    title: 'diets.clientNotes',
    accessor: row => (
      <Button
        style={{
          color: 'white',
          background: '#9C27B0',
          fontSize: '1em',
          textTransform: 'capitalize',
        }}
        onClick={() => {
          handleOpenNotes(row.client);
        }}
        simple
      >
        {t('diets.clientNotes')}
      </Button>
    ),
    name: 'client.notes',
    filterable: false,
    sortable: false,
  },
  {
    title: 'diets.userActiveDietsQuantity',
    accessor: row => (row.client ? row.client?.activeDietsCount : null),
    Filter: ({ onChange, filter }) => (
      <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
    ),
    name: 'client.activeDietsCount',
    filterable: true,
    sortable: true,
  },
  {
    title: 'diets.email',
    accessor: 'client.email',
    name: 'client.email',
  },
  {
    title: 'diets.phone',
    accessor: prop => (
      <a href={`tel:${prop.client?.phone?.number}`}>
        {prop.client?.phone?.number}
      </a>
    ),
    name: 'client.phone.number',
    filterable: true,
    sortable: true,
  },
  {
    title: 'diets.calories',
    accessor: 'calorific.name',
    name: 'calorific.name',
  },
  {
    title: 'diets.diet',
    accessor: 'diet.name',
    name: 'diet.name',
  },
  {
    title: 'diets.variant',
    accessor: 'variant.name',
    name: 'variant.name',
  },
  {
    title: 'diets.address',
    accessor: row =>
      row.address
        ? `${row.address.city} ${row.address.postCode}, ${row.address.street} ${row.address.buildNumber}`
        : row.pickUpPoint
        ? row.pickUpPoint.value
        : '-',
    name: 'address',
    sortable: true,
    filterable: true,
  },
  {
    title: 'diets.priceAfterDiscount',
    accessor: row => row.priceAfterDiscount.toFixed(2),
    Filter: ({ onChange, filter }) => (
      <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
    ),
    name: 'priceAfterDiscount',
    sortable: true,
    filterable: true,
    width: 120,
  },
  {
    title: 'diets.zone',
    accessor: row =>
      row.address && row.address.zone ? `${row.address.zone.name}` : '-',
    name: 'address.zone.name',
    sortable: true,
    filterable: true,
  },
  {
    title: 'clients.marketing',
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row?.client?.marketingTerms} />,
    name: 'client.marketingTerms',
    sortable: false,
    filterable: true,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'diets.testDays',
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.isTest} />,
    name: 'isTest',
    sortable: false,
    filterable: true,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'diets.name',
    accessor: 'name',
    name: 'name',
    sortable: true,
    filterable: true,
  },
];

export default translateColumnConfig;
