import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import isLinkActive from '../helpers/isLinkActive';
import ACTIVE_LINK_OPTIONS from '../constants/activeLinkOptions';
import PATH_PREFIX from '../constants/pathPrefix';
import {
  LINK_BORDER_RADIUS,
  LINK_FOCUSED_BG_COLOR,
} from '../../constants/styles';
import {
  blackColor,
  goldColor,
  hexToRgb,
  navyColor,
} from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles({
  navLink: {
    color: 'inherit',
    borderRadius: LINK_BORDER_RADIUS,
    '&:hover': {
      backgroundColor: LINK_FOCUSED_BG_COLOR,
      color: 'inherit',
    },
    '&:focus': {
      color: 'inherit',
    },
  },
  navLinkActive: {
    backgroundColor: goldColor,
    color: navyColor,
    fontWeight: '500',
    boxShadow: `0 4px 20px 0 rgba(${hexToRgb(
      blackColor
    )},.14), 0 7px 10px -5px rgba(${hexToRgb(goldColor)},.4)`,
    '&:hover': {
      backgroundColor: goldColor,
      color: navyColor,
    },
    '&:focus': {
      color: navyColor,
    },
  },
});

const NavLinkBase = ({
  className,
  children,
  to,
  additionalActivationPaths,
}) => {
  const classes = useStyles();

  return (
    <NavLink
      className={classNames(classes.navLink, className)}
      activeClassName={classes.navLinkActive}
      isActive={(match, location) =>
        !!match ||
        additionalActivationPaths.some(activationPath =>
          isLinkActive(location.pathname, activationPath)
        )
      }
      to={`${PATH_PREFIX}${to}`}
      {...ACTIVE_LINK_OPTIONS}
    >
      {children}
    </NavLink>
  );
};

NavLinkBase.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  additionalActivationPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NavLinkBase;
