import { useTranslation } from 'react-i18next';
import { Settings } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const MyCompany = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_EDIT_COMPANY) && {
      name: t('common.mainMenu.myCompanySettings'),
      to: '/company',
      additionalActivationPaths: getPathSuffixes('/company', ['brands/add']),
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.myCompanyBrands'),
      to: '/brands',
      additionalActivationPaths: getPathSuffixes('/brands', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_ROLE) && {
      name: t('common.mainMenu.myCompanyRoles'),
      to: '/roles',
      additionalActivationPaths: getPathSuffixes('/roles', ['add', 'edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_EMPLOYEE) && {
      name: t('common.mainMenu.myCompanyEmployees'),
      to: '/employees',
      additionalActivationPaths: getPathSuffixes('/employees', [
        'add',
        'edit/:id',
      ]),
    },
    isGranted(roles.ROLE_SHOW_REPORT_ARCHIVE) && {
      name: t('common.mainMenu.archive'),
      to: '/report-archive',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.myCompany')}
      icon={<Settings />}
      links={links}
    />
  );
};

export default MyCompany;
