import { useTranslation } from 'react-i18next';
import { Chat } from '@material-ui/icons';

import SidebarLink from '../../SidebarLink/SidebarLink';

const KasiaModule = () => {
  const { t } = useTranslation();

  return (
    <SidebarLink
      name={t('common.mainMenu.menuSelectionAssistant')}
      to="/menu-selection-assistant"
      icon={<Chat />}
    />
  );
};

export default KasiaModule;
