import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import ModuleNotLoaded from './ModuleNotLoaded';
import SelectInput from '../../../components/FormSelect/SelectInput';
import makeStyles from '@material-ui/styles/makeStyles';

const CODING_VERSIONS = ['v2', 'v3', 'sizes_code'];

const FIELD_MAPPINGS = {
  mainCodeVersion: CODING_VERSIONS,
  alternativeCodeVersion: CODING_VERSIONS,
};

const useStyles = makeStyles({
  table: {
    border: '1px solid #dfdfdf',
    marginTop: 20,
  },
  headerCell: {
    textAlign: 'center',
  },
});

const ScanAndSortModule = ({ classes, handleModuleUpdate, module }) => {
  const customClasses = useStyles();
  const { t } = useTranslation();
  if (!module) {
    return <ModuleNotLoaded moduleName="ScanAndSortModule" />;
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={() =>
              handleModuleUpdate({
                ...module,
                enabled: !module.enabled,
              })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t(
          '$*analyticsTools.modules.scanAndSort.checkboxLabel',
          'Używaj ScanAdnSort'
        )}
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.url')}
        classes={classes}
        name="url"
        value={module.url}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            url: e.target.value,
          })
        }
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.token')}
        maxLength="none"
        classes={classes}
        name="token"
        value={module.token}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            token: e.target.value,
          })
        }
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.refreshToken')}
        maxLength="none"
        classes={classes}
        name="refreshToken"
        value={module.refreshToken}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            refreshToken: e.target.value,
          })
        }
      />
      <FormTextInput
        label={t('$*analyticsTools.modules.scanAndSort.id')}
        classes={classes}
        name="goodspeedBrandId"
        value={module.goodspeedBrandId}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            goodspeedBrandId: e.target.value,
          })
        }
      />
      <Table className={customClasses.table}>
        <TableHead>
          <TableRow>
            <TableCell className={customClasses.headerCell} colSpan={2}>
              {t(
                '$*analyticsTools.modules.scanAndSort.dataMapping.tableHeader',
                'Mapowanie pól'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={customClasses.headerCell}>
              {t(
                '$*analyticsTools.modules.scanAndSort.dataMapping.fieldScanAndSort',
                'Pole ScanAndSort'
              )}
            </TableCell>
            <TableCell className={customClasses.headerCell}>
              {t(
                '$*analyticsTools.modules.scanAndSort.dataMapping.sourceData',
                'Przesyłane dane'
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(FIELD_MAPPINGS).map(field => (
            <TableRow key={field}>
              <TableCell>
                {t(
                  `$*analyticsTools.modules.scanAndSort.dataMapping.fieldScanAndSort.${field}`,
                  field
                )}
              </TableCell>
              <TableCell>
                <SelectInput
                  noGrid
                  classes={classes}
                  mapBy="label"
                  trackBy="value"
                  name={field}
                  value={module?.[field] ?? null}
                  options={FIELD_MAPPINGS[field].map(value => ({
                    value,
                    label: t(
                      `$*analyticsTools.modules.scanAndSort.dataMapping.sourceData.${field}.${value}`,
                      value
                    ),
                  }))}
                  handleChange={e =>
                    handleModuleUpdate({
                      ...module,
                      [field]: e.target.value,
                    })
                  }
                  size={12}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ScanAndSortModule;
