import { sortByKey } from 'helpers/helpers';
import roles from 'helpers/roles';
import Dashboard from 'layouts/Dashboard';
import PowerBIStatistics from 'views/PowerBIStatistics';
import MyProfile from 'views/MyProfile/MyProfile';
import Ingredients from 'views/Ingredients';
import Recipes from 'views/Recipes';
import Meals from 'views/MealsNew';
import MealTypes from 'views/MealTypes';
import Archive from 'views/Archive';
import MealSizes from 'views/MealSizes';
import Diets from 'views/Diets';
import Variants from 'views/Variants';
import Packages from 'views/Packages';
import Overproduction from 'views/Overproduction/Overproduction';
import NewPlanner from 'views/MenuPlanner/NewPlanner/Planner';
import MenuCopying from 'views/MenuPlanner/MenuCopying';
import SubMenu from 'views/MenuPlanner/SubMenu/SubMenu.component';
import SubMenuWrapper from 'views/MenuPlanner/SubMenu';
import Summary from 'views/MenuPlanner/Summary/Summary';
import Dictionary from 'views/Dictionary';

import MyCompanyRouter from 'views/MyCompany';
import AdditionalOfferPage from 'views/AdditionalOffer/AdditionalOfferPage';
import Employees from 'views/Employees';
import Clients from 'views/Clients';
import Brands from 'views/Brands';
import Roles from 'views/Roles';
import PromoCodes from 'views/PromoCodes';
import MailReminders from 'views/MailReminders';
import SmsReminders from 'views/SmsReminders';
import MailNotifications from 'views/MailNotifications';
import SmsNotifications from 'views/SmsNotifications';
import Zones from 'views/Zones/Zones';
import ZonesCategories from 'views/Zones/ZonesCategories';
import Drivers from 'views/Drivers';
import MenuMessages from 'views/MenuPlanner/MenuMessages';
import PagesCreator from 'views/PagesCreator';
import MassSms from 'views/MassSms';
import MassEmail from 'views/MassEmail';
import Waste from './views/Waste/Waste';
import ReportView from './views/Waste/ReportView';
import Addons from 'views/Addons/Addons/Addons';
import AddonsCategories from 'views/Addons/AddonsCategories/AddonsCategories';
import Contractors from 'views/Contractors/Contractors';

import UsageOfDiscountCodes from 'views/Reports/UsageOfDiscountCodes';
import MoneyBoxTopUp from './views/Reports/MoneyBoxTopUp';

import AddonsReport from 'views/Reports/AddonsReport';
import AddonsByClientReport from 'views/Reports/AddonsByClientReport';
import ShoppingList from 'views/Reports/ShoppingList';
import ForDriver from 'views/Reports/ForDriver';
import OverproductionReport from 'views/Reports/Overproduction';
import LogisticsAddresses from 'views/LogisticsAddresses/List';
import BoxLabels from 'views/Reports/BoxLabels';
import BoxLabelsDateRange from 'views/Reports/BoxLabelsDateRange';
import BoxLabelsMini from 'views/Reports/BoxLabelsMini';
import PdfGenerator from 'views/Reports/PdfGenerator/';
import DishLabels from 'views/Reports/DishLabels';
import RecipeCards from 'views/Reports/RecipeCards';
import RecipeIngredients from 'views/Reports/RecipeIngredients';
import DishCards from 'views/Reports/DishCards';
import Containers from 'views/Reports/Containers';
import SentSms from 'views/Reports/SentSms';
import DishesToProduction from 'views/Reports/DishesToProduction';
import Orders from 'views/Orders/Orders';
import Baskets from 'views/Baskets';
import EcommerceDiets from 'views/Orders/Diets';
import Subscriptions from 'views/Orders/Subscriptions';
import Comments from 'views/Comments/Comments';
import EcommerceBags from 'views/EcommerceBags/EcommerceBags';
import MassActionDietElements from 'views/MassAction/DietElements';
import DishLabelsSubBrands from './views/Reports/DishLabelsSubBrands';
import ActiveBrandInformation from './views/Brands/ActiveBrandInformation';
import RecipesChecklist from './views/Reports/RecipesChecklist';
import DishesChecklist from './views/Reports/DishesChecklist';
import FoodCost from './views/Reports/FoodCost';
import IncomeSummary from './views/Reports/IncomeSummary';
import FoodCostBags from './views/Reports/FoodCostBags';
import IncomingSubscriptions from './views/Reports/IncomingSubscriptions';
import ReportsGlobalConfiguration from './views/Reports/ReportsGlobalConfiguration';
import ActiveDiets from './views/Reports/ActiveDiets';
import OrderedDiets from './views/Reports/OrderedDiets';
import PersonalPickups from './views/Reports/PersonalPickups';
import Packers from './views/Reports/Packers';
import Disposal from './views/Reports/Disposal';
import RecipeStickers from './views/Reports/RecipeStickers';
import Shop from 'views/Shop';
import RoutePlanner from './views/RoutePlanner';
import MacroIngredients from './views/Reports/MacroIngredients';

import SettlementsSettings from './views/Settlements/Settings';
import LanguageSettings from './views/Translations/LanguageSettings';
import TranslationsAdminPanel from './views/Translations/AdminPanel';
import TranslationsClientPanel from './views/Translations/ClientPanel';
import TranslationsAPI from './views/Translations/API';

import PaymentMethods from './views/BrandConfiguration/PaymentMethods';
import DeliveriesAndOrders from './views/BrandConfiguration/DeliveriesAndOrders';
import DeliveryOptions from './views/BrandConfiguration/DeliveryOptions';
import LoginOptions from './views/BrandConfiguration/LoginOptions';
import EcoContainers from './views/BrandConfiguration/EcoContainers';
import MailAndSms from './views/BrandConfiguration/MailAndSms';
import AnalyticsTools from './views/BrandConfiguration/analyticsTools/AnalyticsTools';
import PowerBI from './views/BrandConfiguration/PowerBI';

import PanelConfiguration from './views/BrandConfiguration/PanelConfiguration/PanelConfiguration.container';
import ClientPanelDesign from './views/BrandConfiguration/ClientPanelDesign';
import OrderForm from './views/BrandConfiguration/OrderForm';
import Accounting from './views/BrandConfiguration/Accounting';
import { default as MSA } from './views/MenuSelectionAssistant/MenuSelectionAssistant';
import AccessKeys from './views/BrandConfiguration/AccessKeys';
import ProductFeed from './views/BrandConfiguration/ProductFeed';
import {
  BagsPackhouse,
  DishesPackhouse,
  Kitchen,
  TasksGenerator,
} from './views/CateringsFlow';

import {
  AdditionalPrices,
  DietDelivery,
  DietDiscounts,
  DietPrices,
  MoneyBoxConfigurations,
} from 'views/Prices';

import LoginPage from 'views/Pages/LoginPage';
import ResetPassword from 'views/Pages/ResetPassword';
import HomeCmsPage from './views/CMS/HomePage/HomePage';
import AddonsCmsPage from './views/CMS/Addons';
import DietsCmsPage from './views/CMS/Diets';
import MealsCmsPage from './views/CMS/Meals';
import ZonesCmsPage from './views/CMS/Zones';

import Announcement from '@material-ui/icons/Announcement';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Assignment from '@material-ui/icons/Assignment';
import LocalDining from '@material-ui/icons/LocalDining';
import LocalPlay from '@material-ui/icons/LocalPlay';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Tune from '@material-ui/icons/Tune';
import ColorLens from '@material-ui/icons/ColorLens';
import CheckBox from '@material-ui/icons/CheckBox';
import LocalShipping from '@material-ui/icons/LocalShipping';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import RoomService from '@material-ui/icons/RoomService';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import BarChart from '@material-ui/icons/BarChart';
import TrendingUp from '@material-ui/icons/TrendingUp';
import ExtensionIcon from '@material-ui/icons/Extension';
import { AccountBalance, DeleteOutline, GraphicEq } from '@material-ui/icons';
import Chat from '@material-ui/icons/Chat';

import Book from '@material-ui/icons/Book';
import Settings from '@material-ui/icons/Settings';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import FlagIcon from '@material-ui/icons/Flag';
import './i18n';
import AddressRequests from './views/AddressRequest/AddressRequests';
import DietsChecklist from './views/Reports/DietsChecklist';
import AlteredDietsChecklist from './views/Reports/AlteredDietsChecklist';
import TotalBel from './views/Reports/TotalBel';
import EmployeesReport from './views/Reports/EmployeesReport/EmployeesReport';
import BillingReport from './views/Reports/BillingReport';
import AccountantReports from './views/Reports/Accountant';
import { ReactComponent as CalculateIcon } from 'assets/img/CalculateIcon.svg';

import DICTIONARIES_TYPES from 'views/Dictionary/dictionariesTypes.const';
import Products from './views/Products';
import RouteManager from 'views/RouteManager';
import BlogCreator from 'views/CMS/Blog/index';
import SummaryV2 from 'views/MenuPlanner/SummaryV2/SummaryV2';
import DishesPhotoGallery from 'views/DishesPhotoGallery/DishesPhotoGallery';
import ActiveDietsTable from './views/Reports/ActiveDietsTable';
import SelectionsV3Codes from 'views/Reports/SelectionsV3Codes';
import ClientsBmi from 'views/ClientsBmi/ClientsBmi';
import FilterLineDiets from 'views/FilterLineDiets';
import CopyingBrandSettings from 'views/CopyingBrandSettings';
import ReportsBoxLabelsBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/ReportsBoxLabelsBreadcrumbAdditionalContent';
import MailRemindersBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/MailRemindersBreadcrumbAdditionalContent';
import AddonsBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/AddonsBreadcrumbAdditionalContent';
import MailNotificationsBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/MailNotificationsBreadcrumbAdditionalContent';
import ConfigurationPaymentMethodBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/ConfigurationPaymentMethodBreadcrumbAdditionalContent';
import ConfigurationOrderFormBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/ConfigurationOrderFormBreadcrumbAdditionalContent';
import DiscountCodeBreadcrumbAdditionalContent from 'components/BreadcrumbAdditionalContent/DiscountCodeBreadcrumbAdditionalContent';

const routes = [
  {
    path: '/dashboard',
    name: 'common.mainMenu.userPanelCaption',
    icon: <DashboardIcon />,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/my-profile',
    name: 'common.mainMenu.myProfile',
    component: MyProfile,
    layout: '/admin',
  },
  {
    path: '/login',
    name: 'common.mainMenu.login',
    component: props => <LoginPage {...props} />,
    layout: '/auth',
  },
  {
    path: '/reset-password/:token',
    name: 'common.mainMenu.passwordReset',
    component: props => <ResetPassword {...props} />,
    layout: '/auth',
  },
  {
    collapse: true,
    name: 'common.mainMenu.dashboard',
    icon: <DashboardIcon />,
    views: sortByKey(
      [
        {
          path: '/dashboard',
          name: 'common.mainMenu.dashboardStatistics',
          component: Dashboard,
          layout: '/admin',
        },
        {
          path: '/powerBIStatistics',
          name: 'common.mainMenu.powerBIStatistics',
          component: PowerBIStatistics,
          layout: '/admin',
          role: roles.ROLE_SHOW_BRAND_POWER_BI,
        },
      ],
      'name'
    ),
  },
  {
    collapse: true,
    name: 'common.mainMenu.dictionariesCaption',
    icon: <Book />,
    views: sortByKey(
      [
        {
          path: '/containers',
          name: 'common.mainMenu.containers',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.CONTAINERS,
            valueUrl: 'containers',
            tableName: 'common.mainMenu.containerTableName',
            tableTitle: 'common.mainMenu.containerTableTitle',
            customRemoveBody: true,
          },
        },
        {
          path: '/home-requirements',
          name: 'common.mainMenu.homeRequirements',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.homeRequirementsName',
            tableTitle: 'common.mainMenu.homeRequirementsTitle',
            valueUrl: 'home-requirements',
          },
        },
        {
          path: '/serving-suggestions',
          name: 'common.mainMenu.servingSuggestions',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.servingSuggestionsTableName',
            tableTitle: 'common.mainMenu.servingSuggestionsTableTitle',
            valueUrl: 'serving-suggestions',
            translatable: true,
          },
        },
        {
          path: '/allergens',
          name: 'common.mainMenu.allergens',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_ANTI_PREFERENCES,
            tableName: 'common.mainMenu.allergensTableName',
            tableTitle: 'common.mainMenu.allergensTableTitle',
            valueUrl: 'allergens',
            translatable: true,
            antiPreferencesKey: 'allergens',
          },
        },
        {
          path: '/bag-containers',
          name: 'common.mainMenu.bagContainers',
          component: Dictionary,
          role: roles.ROLE_SHOW_BAG_CONTAINER,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.BAG_CONTAINERS,
            tableName: 'common.mainMenu.bagContainersName',
            tableTitle: 'common.mainMenu.bagContainersTitle',
            valueUrl: 'bag-containers',
            showRole: roles.ROLE_SHOW_BAG_CONTAINER,
            editRole: roles.ROLE_EDIT_BAG_CONTAINER,
            createRole: roles.ROLE_CREATE_BAG_CONTAINER,
          },
        },
        {
          path: '/delivery-types',
          name: 'common.mainMenu.deliveryTypes',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.deliveryTypesTableName',
            tableTitle: 'common.mainMenu.deliveryTypesTableTitle',
            valueUrl: 'delivery-types',
          },
        },
        {
          path: '/ingredient-categories',
          name: 'common.mainMenu.ingredientCategories',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_ANTI_PREFERENCES,
            tableName: 'common.mainMenu.ingredientCategoriesTableName',
            tableTitle: 'common.mainMenu.ingredientCategoriesTableTitle',
            valueUrl: 'ingredient-categories',
            antiPreferencesKey: 'ingredientCategories',
            translatable: true,
          },
        },
        {
          path: '/units',
          name: 'common.mainMenu.units',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.unitsTableName',
            tableTitle: 'common.mainMenu.unitsTableTitle',
            valueUrl: 'units',
          },
        },
        {
          path: '/tags',
          name: 'common.mainMenu.tagsForProduction',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.tagsForProductionTableName',
            tableTitle: 'common.mainMenu.tagsForProductionTableTitle',
            valueUrl: 'tags',
          },
        },
        {
          path: '/client-tags',
          name: 'common.mainMenu.tagsForClients',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.CLIENT_TAGS,
            tableName: 'common.mainMenu.tagsForClientsTableName',
            tableTitle: 'common.mainMenu.tagsForClientsTableTitle',
            valueUrl: 'client-tags',
            antiPreferencesKey: 'clientTags',
            translatable: true,
          },
        },
        {
          path: '/sms-client-tags',
          name: 'common.mainMenu.tagsForClientsSms',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.tagsForClientsTableNameSms',
            tableTitle: 'common.mainMenu.tagsForClientsTableTitleSms',
            valueUrl: 'sms-client-tags',
          },
        },
        {
          path: '/eco-containers',
          name: 'common.mainMenu.ecoContainers',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.CONTAINERS,
            valueUrl: 'eco-containers',
            tableName: 'common.mainMenu.ecoContainersTableName',
            tableTitle: 'common.mainMenu.ecoContainersTableTitle',
            customRemoveBody: true,
          },
        },
        {
          path: '/wholesalers',
          name: 'common.mainMenu.wholesalers',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.wholesalersTableName',
            tableTitle: 'common.mainMenu.wholesalersTableTitle',
            valueUrl: 'wholesalers',
          },
        },
        {
          path: '/sexes',
          name: 'common.mainMenu.sex',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_POSITION,
            tableName: 'common.mainMenu.sexTableName',
            tableTitle: 'common.mainMenu.sexTableTitle',
            valueUrl: 'sexes',
            translatable: true,
          },
        },
        {
          path: '/how-knows',
          name: 'common.mainMenu.howKnow',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.WITH_POSITION,
            tableName: 'common.mainMenu.howKnowTableName',
            tableTitle: 'common.mainMenu.howKnowTableTitle',
            valueUrl: 'how-knows',
          },
        },
        {
          path: '/sources',
          name: 'common.mainMenu.sources',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            type: DICTIONARIES_TYPES.REGULAR,
            tableName: 'common.mainMenu.sourcesTableName',
            tableTitle: 'common.mainMenu.sourcesTableTitle',
            valueUrl: 'sources',
          },
        },
        {
          path: '/kitchens',
          name: 'common.mainMenu.kitchen',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.kitchenTableName',
            tableTitle: 'common.mainMenu.kitchenTableTitle',
            valueUrl: 'kitchens',
          },
        },
        {
          path: '/meal-categories',
          name: 'common.mainMenu.mealCategory',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.mealCategory',
            tableTitle: 'common.mainMenu.mealCategory',
            valueUrl: 'meal-categories',
          },
        },
        {
          path: '/departments',
          name: 'common.mainMenu.department',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.departmentTableName',
            tableTitle: 'common.mainMenu.departmentTableTitle',
            valueUrl: 'departments',
          },
        },
        {
          path: '/stowages',
          name: 'common.mainMenu.stowage',
          component: Dictionary,
          role: roles.ROLE_SHOW_DICTIONARY,
          layout: '/admin',
          componentProps: {
            tableName: 'common.mainMenu.stowageTableName',
            tableTitle: 'common.mainMenu.stowageTableTitle',
            valueUrl: 'stowages',
          },
        },
        {
          path: '/contractors',
          name: 'common.mainMenu.contractors',
          component: Contractors,
          role: roles.ROLE_SHOW_CONTRACTOR,
          layout: '/admin',
        },
      ],
      'name'
    ),
  },
  {
    collapse: true,
    name: 'common.mainMenu.companyOffer',
    icon: <BusinessCenter />,
    views: [
      {
        path: '/sizes',
        name: 'common.mainMenu.sizes',
        component: MealSizes,
        role: roles.ROLE_SHOW_SIZE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/sizes',
        },
      },
      {
        path: '/types',
        name: 'common.mainMenu.typesOfMeals',
        component: MealTypes,
        role: roles.ROLE_SHOW_MEAL_TYPE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/types',
        },
      },
      {
        path: '/diets',
        name: 'common.mainMenu.diets',
        component: Diets,
        role: roles.ROLE_SHOW_DIET,
        layout: '/admin',
        componentProps: {
          valueUrl: '/diets',
        },
      },
      {
        path: '/variants',
        name: 'common.mainMenu.variants',
        component: Variants,
        role: roles.ROLE_SHOW_VARIANT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/variants',
        },
      },
      {
        path: '/packages',
        name: 'common.mainMenu.dietFilters',
        component: Packages,
        role: roles.ROLE_SHOW_PACKAGE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/packages',
        },
      },
      {
        path: '/filter-line-diets',
        name: 'common.mainMenu.dietLineFilters',
        component: FilterLineDiets,
        role: roles.ROLE_SHOW_PACKAGE_LINE_FILTER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/packages',
        },
      },
      {
        path: '/mass-actions-diet-elements',
        name: 'massAction.dietElements.title',
        component: MassActionDietElements,
        role: roles.ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.recipesDatabase',
    icon: <Assignment />,
    views: [
      {
        path: '/products',
        name: 'common.mainMenu.products',
        component: Products,
        role: roles.ROLE_SHOW_PRODUCT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/products',
        },
      },
      {
        path: '/ingredients',
        name: 'common.mainMenu.ingredients',
        component: Ingredients,
        role: roles.ROLE_SHOW_INGREDIENT,
        layout: '/admin',
        componentProps: {
          valueUrl: '/ingredients',
        },
      },
      {
        path: '/recipes',
        name: 'common.mainMenu.recipes',
        component: Recipes,
        role: roles.ROLE_SHOW_RECIPE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/recipes',
        },
      },
      {
        path: '/meals',
        name: 'common.mainMenu.meals',
        component: Meals,
        role: roles.ROLE_SHOW_DISH,
        layout: '/admin',
        componentProps: {
          valueUrl: '/meals',
        },
      },
      {
        path: '/dish-photo-gallery',
        name: 'dishes.gallery.title',
        component: DishesPhotoGallery,
        role: roles.ROLE_SHOW_DISH_PHOTO_GALLERY,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/menu-summary/:date/:variant',
    name: 'common.mainMenu.menuSummary',
    component: Summary,
    role: roles.ROLE_SHOW_MENU_SUMMARY,
    layout: '/admin',
  },
  {
    path: '/seccond-menu-summary/:date/:variant',
    name: 'common.mainMenu.menuSummary.v2',
    component: SummaryV2,
    role: roles.ROLE_SHOW_MENU_SUMMARY,
    layout: '/admin',
  },
  {
    name: 'common.mainMenu.menuPlanner',
    icon: <LocalDining />,
    collapse: true,
    layout: '/admin',
    views: [
      {
        path: '/menu-planner/:date',
        sidebarPath: '/menu-planner',
        name: 'common.mainMenu.menu',
        component: NewPlanner,
        role: roles.ROLE_SHOW_MENU_PLANNING,
        layout: '/admin',
      },
      {
        path: '/menu-planner',
        name: 'common.mainMenu.menu',
        component: NewPlanner,
        role: roles.ROLE_SHOW_MENU_PLANNING,
        layout: '/admin',
      },
      {
        path: `/menu-summary`,
        name: 'common.mainMenu.menuSummary',
        component: Summary,
        role: roles.ROLE_SHOW_MENU_SUMMARY,
        layout: '/admin',
      },
      {
        path: '/seccond-menu-summary',
        name: 'common.mainMenu.menuSummary.v2',
        component: SummaryV2,
        role: roles.ROLE_SHOW_MENU_SUMMARY,
        layout: '/admin',
      },
      {
        path: '/menu-copying',
        sidebarPath: '/menu-copying',
        name: '$*common.mainMenu.menu.copying',
        component: MenuCopying,
        role: roles.ROLE_CREATE_MENU_PLANNING,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'common.mainMenu.additionalOrders',
    icon: <TrendingUp />,
    collapse: true,
    role: roles.ROLE_SHOW_OVERPRODUCTION,
    layout: '/admin',
    views: [
      {
        path: '/sub-brands',
        name: 'common.mainMenu.subBrands',
        component: Dictionary,
        role: roles.ROLE_SHOW_SUB_BRAND,
        layout: '/admin',
        componentProps: {
          type: DICTIONARIES_TYPES.REGULAR,
          tableName: 'common.mainMenu.subBrandsTableName',
          tableTitle: 'common.mainMenu.subBrandsTableTitle',
          valueUrl: 'sub-brands',
          valueKey: 'name',
          valueAccessor: 'name',
          showRole: roles.ROLE_SHOW_SUB_BRAND,
          createRole: roles.ROLE_CREATE_SUB_BRAND,
          removeRole: roles.ROLE_REMOVE_SUB_BRAND,
          editRole: roles.ROLE_EDIT_SUB_BRAND,
          roleName: 'SUB_BRAND',
        },
      },
      {
        path: '/overprod-planner',
        name: 'common.mainMenu.newOrders',
        component: Overproduction,
        role: roles.ROLE_SHOW_OVERPRODUCTION,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/menu-summary',
    name: 'common.mainMenu.menuSummary',
    component: Summary,
    role: roles.ROLE_SHOW_MENU_PLANNING,
    layout: '/admin',
  },
  {
    name: 'common.mainMenu.waste',
    icon: <DeleteOutline />,
    collapse: true,
    role: roles.ROLE_SHOW_OVERPRODUCTION,
    layout: '/admin',
    views: [
      {
        path: '/add-waste',
        name: 'common.mainMenu.addWaste',
        component: Waste,
        role: roles.ROLE_SHOW_WASTE,
        layout: '/admin',
      },
      {
        path: '/waste-report',
        name: 'common.mainMenu.wasteReport',
        component: ReportView,
        role: roles.ROLE_SHOW_REPORT_WASTE,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.priceLists',
    icon: <ShoppingCart />,
    views: [
      {
        path: '/prices',
        name: 'common.mainMenu.dietPrices',
        component: DietPrices,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.dietPricesTableName',
          tableTitle: '',
        },
      },
      {
        path: '/delivery',
        name: 'common.mainMenu.delivery',
        component: DietDelivery,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.deliveryTableName',
          tableTitle: '',
        },
      },
      {
        path: '/discounts',
        name: 'common.mainMenu.discounts',
        component: DietDiscounts,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.discountsTableName',
          tableTitle: '',
        },
      },
      {
        path: '/additionals',
        name: 'common.mainMenu.additionalPrices',
        component: AdditionalPrices,
        role: roles.ROLE_SHOW_COST,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.additionalPricesTableName',
          tableTitle: '',
        },
      },
      {
        path: '/money-box-config',
        name: 'common.mainMenu.moneyBoxConfig',
        component: MoneyBoxConfigurations,
        role: roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.addons',
    icon: <ExtensionIcon />,
    views: [
      {
        path: '/addons',
        name: 'common.mainMenu.addonsSubmenu',
        component: Addons,
        role: roles.ROLE_SHOW_ADDON,
        layout: '/admin',
        breadcrumbAdditionalContent: <AddonsBreadcrumbAdditionalContent />,
      },
      {
        path: '/addon-categories',
        name: 'common.mainMenu.addonsCategories',
        component: AddonsCategories,
        role: roles.ROLE_SHOW_ADDON,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/messages',
    name: 'common.mainMenu.messages',
    icon: <Announcement />,
    component: MenuMessages,
    role: roles.ROLE_SHOW_NOTIFICATION,
    layout: '/admin',
    componentProps: {
      tableName: 'Komunikaty',
      tableTitle: '',
      valueUrl: '/messages',
    },
  },
  {
    path: '/discount-codes',
    name: 'common.mainMenu.discountCodes',
    icon: <LocalPlay />,
    component: PromoCodes,
    role: roles.ROLE_SHOW_DISCOUNT_CODE,
    layout: '/admin',
    componentProps: {
      valueUrl: '/discount-codes',
    },
    breadcrumbAdditionalContent: <DiscountCodeBreadcrumbAdditionalContent />,
  },
  {
    collapse: true,
    name: 'common.mainMenu.roomService',
    icon: <RoomService />,
    views: [
      {
        path: '/clients',
        name: 'common.mainMenu.clients',
        component: Clients,
        role: roles.ROLE_SHOW_USER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/clients',
        },
      },
      {
        path: '/baskets',
        name: 'basket.menu',
        component: Baskets,
        role: roles.ROLE_SHOW_BASKET,
        layout: '/admin',
      },
      {
        path: '/orders',
        name: 'common.mainMenu.orders',
        component: Orders,
        role: roles.ROLE_SHOW_ORDER,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.orders',
          tableTitle: 'common.mainMenu.orders',
          valueUrl: '/orders',
        },
      },
      {
        path: '/ecommerce-diets',
        name: 'common.mainMenu.dietList',
        component: EcommerceDiets,
        role: roles.ROLE_SHOW_ORDER,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.orders',
          tableTitle: 'common.mainMenu.orders',
          valueUrl: '/ecommerce-diets',
        },
      },
      {
        path: '/clients-bmi',
        name: 'common.mainMenu.clientsBmi',
        component: ClientsBmi,
        role: roles.ROLE_SHOW_CLIENT_METRIC,
        layout: '/admin',
        componentProps: {
          tableTitle: 'common.mainMenu.clientsBmi',
          valueUrl: '/clients-bmi',
        },
      },
      {
        path: '/ecommerce-bags',
        name: 'ecommerceBags.name',
        component: EcommerceBags,
        role: roles.ROLE_SHOW_BAG,
        layout: '/admin',
      },
      {
        path: '/subscriptions',
        name: 'common.mainMenu.subscriptions',
        component: Subscriptions,
        role: roles.ROLE_SHOW_ORDER,
        layout: '/admin',
        componentProps: {
          tableName: 'common.mainMenu.subscriptions',
          tableTitle: 'common.mainMenu.subscriptions',
          valueUrl: '/subscription',
        },
      },
      {
        path: '/rates',
        name: 'common.mainMenu.clientComments',
        component: Comments,
        role: roles.ROLE_SHOW_RATE,
        layout: '/admin',
      },
      {
        path: '/mass-sms',
        name: 'common.mainMenu.sms',
        component: MassSms,
        role: roles.ROLE_SHOW_MASS_SMS,
        layout: '/admin',
      },
      {
        path: '/mass-mails',
        name: 'common.mainMenu.mail',
        component: MassEmail,
        role: roles.ROLE_SHOW_MASS_MAIL,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/menu-selection-assistant',
    name: 'common.mainMenu.menuSelectionAssistant',
    icon: <Chat />,
    component: MSA,
    role: roles.ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'common.mainMenu.logistics',
    icon: <LocalShipping />,
    views: [
      {
        path: '/zone-categories',
        name: 'common.mainMenu.zoneCategories',
        component: ZonesCategories,
        role: roles.ROLE_SHOW_ZONE_CATEGORY,
        layout: '/admin',
        componentProps: {
          tableName: 'zoneCategories.add',
          tableTitle: 'zoneCategories.list',
          valueUrl: 'zone-categories',
        },
      },
      {
        path: '/zones',
        name: 'common.mainMenu.zones',
        component: Zones,
        role: roles.ROLE_SHOW_ZONE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/zones',
        },
      },
      {
        path: '/logistics-addresses',
        name: 'common.mainMenu.addresses',
        component: LogisticsAddresses,
        role: roles.ROLE_SHOW_ADDRESS,
        layout: '/admin',
        componentProps: {
          valueUrl: '/logistics-addresses',
        },
      },
      {
        path: '/drivers',
        name: 'common.mainMenu.drivers',
        component: Drivers,
        role: roles.ROLE_SHOW_DRIVER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/drivers',
        },
      },
      {
        path: '/pick-up-points',
        name: 'common.mainMenu.pickupPoints',
        component: Dictionary,
        role: roles.ROLE_SHOW_DICTIONARY,
        layout: '/admin',
        componentProps: {
          type: DICTIONARIES_TYPES.REGULAR,
          tableName: 'pickupPoints.add',
          tableTitle: 'pickupPoints.list',
          valueUrl: 'pick-up-points',
        },
      },
      {
        path: '/route-planner',
        name: 'routePlanner.menuTitle',
        component: RoutePlanner,
        role: roles.ROLE_SHOW_LOGISTIC_ROUTES,
        layout: '/admin',
        componentProps: {
          valueUrl: '/route-planner',
        },
      },
      {
        path: '/route-manager',
        name: 'routeManager.menuTitle',
        component: RouteManager,
        role: roles.ROLE_SHOW_LOGISTIC_ROUTE_MANAGER,
        layout: '/admin',
        componentProps: {
          valueUrl: '/route-manager',
        },
      },
      {
        path: '/address-requests',
        name: 'common.mainMenu.addressRequests',
        component: AddressRequests,
        role: roles.ROLE_SHOW_ADDRESS_REQUESTS,
        layout: '/admin',
        componentProps: {
          valueUrl: '/address-requests',
        },
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.caterings.productions',
    icon: <PlaylistAddCheck />,
    views: [
      {
        icon: <Settings />,
        path: '/reports-configuration',
        name: 'common.mainMenu.reportsGlobalConfig',
        role: roles.ROLE_EDIT_COMPANY,
        component: ReportsGlobalConfiguration,
        layout: '/admin',
      },
      {
        path: '/shopping-report',
        name: 'common.mainMenu.reportsShoppingList',
        role: roles.ROLE_SHOW_REPORT_SHOPPING,
        component: ShoppingList,
        layout: '/admin',
      },
      {
        path: '/purchased-addons-report',
        name: 'common.mainMenu.reportsAddonsList.newTranslate',
        role: roles.ROLE_SHOW_REPORT_PURCHASED_ADDONS,
        component: AddonsReport,
        layout: '/admin',
      },
      {
        path: '/client-purchased-addons-report',
        name: 'common.mainMenu.reportsAddonsByClientList',
        role: roles.ROLE_SHOW_REPORT_PURCHASED_ADDONS_BY_CLIENT,
        component: AddonsByClientReport,
        layout: '/admin',
      },
      {
        path: '/containers-report',
        name: 'common.mainMenu.reportsContainers',
        role: roles.ROLE_SHOW_REPORT_CONTAINERS,
        component: Containers,
        layout: '/admin',
        componentProps: {
          isDetailed: false,
        },
      },
      {
        path: '/detailed-containers-report',
        name: 'common.mainMenu.reportsDetailedContainers',
        role: roles.ROLE_SHOW_REPORT_CONTAINERS_DETAIL,
        component: Containers,
        layout: '/admin',
        componentProps: {
          isDetailed: true,
        },
      },
      {
        path: '/checklist-recipes-report',
        name: 'common.mainMenu.reportsRecipesChecklist',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_RECIPES,
        component: RecipesChecklist,
        layout: '/admin',
      },
      {
        path: '/recipe-cards-report',
        name: 'common.mainMenu.reportsRecipeCards',
        role: roles.ROLE_SHOW_REPORT_RECIPES_CARD,
        component: RecipeCards,
        layout: '/admin',
      },
      {
        path: '/recipe-ingredients-report',
        name: 'common.mainMenu.reportsRecipeIngredients',
        role: roles.ROLE_SHOW_REPORT_RECIPES_INGREDIENTS,
        component: RecipeIngredients,
        layout: '/admin',
      },
      {
        path: '/checklist-dishes-report',
        name: 'common.mainMenu.reportsDishesChecklist',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_DISHES,
        component: DishesChecklist,
        layout: '/admin',
      },
      {
        path: '/dish-cards-report',
        name: 'common.mainMenu.reportsDishCards',
        role: roles.ROLE_SHOW_REPORT_DISHES_CARD,
        component: DishCards,
        layout: '/admin',
      },
      {
        path: '/dish-labels-report',
        name: 'common.mainMenu.reportsDishLabels',
        role: roles.ROLE_SHOW_REPORT_DISH_STICKERS,
        component: DishLabels,
        layout: '/admin',
      },
      {
        path: '/overprod-dish-stickers-report',
        name: 'common.mainMenu.reportsDishLabelsAdditional',
        roles: [
          roles.ROLE_SHOW_REPORT_DISH_STICKERS,
          roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
        ],
        component: DishLabelsSubBrands,
        layout: '/admin',
      },
      {
        path: '/mini-box-labels-report',
        name: 'common.mainMenu.reportsBoxLabels-Mini',
        role: roles.ROLE_SHOW_REPORT_BAG_STICKERS_MINI,
        component: BoxLabelsMini,
        layout: '/admin',
      },
      {
        path: '/dishes-to-production-report',
        name: 'common.mainMenu.reportsDishesToProduction',
        role: roles.ROLE_SHOW_REPORT_DISH_LIST,
        component: DishesToProduction,
        layout: '/admin',
      },
      {
        path: '/checklist-diets-report',
        name: 'common.mainMenu.reportsDietsChecklist',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_DIETS,
        component: DietsChecklist,
        layout: '/admin',
      },
      {
        path: '/checklist-altered-diets-report',
        name: 'common.mainMenu.reportsAlteredDietsChecklist',
        role: roles.ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS,
        component: AlteredDietsChecklist,
        layout: '/admin',
      },
      {
        path: '/active-diets-report',
        name: 'common.mainMenu.reportActiveDiets',
        role: roles.ROLE_SHOW_REPORT_ACTIVE_DIET,
        component: ActiveDiets,
        layout: '/admin',
      },
      {
        path: '/table-active-diets-report',
        name: 'common.mainMenu.reportActiveDietsTable',
        role: roles.ROLE_SHOW_REPORT_ACTIVE_DIET,
        component: ActiveDietsTable,
        layout: '/admin',
      },
      {
        path: '/box-labels-report',
        name: 'common.mainMenu.reportsBoxLabels',
        breadcrumbAdditionalContent: (
          <ReportsBoxLabelsBreadcrumbAdditionalContent />
        ),
        role: roles.ROLE_SHOW_REPORT_BAG_STICKERS,
        component: BoxLabels,
        layout: '/admin',
      },
      {
        path: '/box-date-range-labels-report',
        name: 'common.mainMenu.reportsBoxLabelsDateRange',
        role: roles.ROLE_SHOW_REPORT_BAG_STICKERS,
        component: BoxLabelsDateRange,
        layout: '/admin',
      },
      {
        path: '/personal-pickups-report',
        name: 'common.mainMenu.reportPersonalPickups',
        role: roles.ROLE_SHOW_REPORT_PERSONAL_PICKUPS,
        component: PersonalPickups,
        layout: '/admin',
      },
      {
        path: '/packers-report',
        name: 'common.mainMenu.reportPackers',
        role: roles.ROLE_SHOW_REPORT_PACKERS,
        component: Packers,
        layout: '/admin',
      },
      {
        path: '/for-driver-report',
        name: 'common.mainMenu.reportsForDriver',
        role: roles.ROLE_SHOW_REPORT_FOR_DRIVER,
        component: ForDriver,
        layout: '/admin',
      },
      {
        path: '/stickers-recipe-report',
        name: 'common.mainMenu.reportRecipeStickers',
        role: roles.ROLE_SHOW_REPORT_RECIPE_STICKERS,
        component: RecipeStickers,
        layout: '/admin',
      },
      {
        path: '/total-bel-report',
        name: 'common.mainMenu.totalBelReport',
        role: roles.ROLE_SHOW_REPORT_TOTAL_BEL,
        component: TotalBel,
        layout: '/admin',
      },
      {
        path: '/pdf-generator-report',
        name: 'common.mainMenu.reportsPdfGenerator',
        role: roles.ROLE_SHOW_REPORT_ADVANCED_DISH_PDF,
        component: PdfGenerator,
        layout: '/admin',
      },
      {
        path: '/selections-report',
        name: 'common.mainMenu.reportsSelections',
        role: roles.ROLE_SHOW_REPORT_SELECTIONS,
        component: SelectionsV3Codes,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.caterings.analitics',
    icon: <BarChart />,
    views: [
      {
        path: '/income-summary-report',
        name: 'common.mainMenu.reportsIncomeSummary',
        role: roles.ROLE_SHOW_REPORT_INCOME_SUMMARY,
        component: IncomeSummary,
        layout: '/admin',
      },
      {
        path: '/bags-food-cost-report',
        name: 'common.mainMenu.reportsFoodCostBags',
        role: roles.ROLE_SHOW_REPORT_FOOD_COST_BAGS,
        component: FoodCostBags,
        layout: '/admin',
      },
      {
        path: '/food-cost-report',
        name: 'common.mainMenu.reportsFoodCost',
        role: roles.ROLE_SHOW_REPORT_FOOD_COST,
        component: FoodCost,
        layout: '/admin',
      },
      {
        path: '/ordered-diets-report',
        name: 'common.mainMenu.reportOrderedDiets',
        role: roles.ROLE_SHOW_REPORT_ORDERED_DIET,
        component: OrderedDiets,
        layout: '/admin',
      },
      {
        path: '/usage-of-discount-codes-report',
        name: 'common.mainMenu.reportsDiscountCodes',
        role: roles.ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES,
        component: UsageOfDiscountCodes,
        layout: '/admin',
      },
      {
        path: '/money-box-top-up-report',
        name: 'common.mainMenu.reportsMoneyboxTopUp',
        role: roles.ROLE_SHOW_REPORT_MONEY_BOX_TOP_UP,
        component: MoneyBoxTopUp,
        layout: '/admin',
      },
      {
        path: '/incoming-subscriptions-report',
        name: 'common.mainMenu.reportsIncomingSubscriptions',
        role: roles.ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION,
        component: IncomingSubscriptions,
        layout: '/admin',
      },
      {
        path: '/sent-sms-report',
        name: 'reports.reportSentSms',
        role: roles.ROLE_SHOW_REPORT_SENT_SMS,
        component: SentSms,
        layout: '/admin',
        componentProps: {
          isDetailed: false,
        },
      },
      {
        path: '/macro-ingredients-report',
        name: 'common.mainMenu.macroIngredientsReport',
        role: roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS,
        component: MacroIngredients,
        layout: '/admin',
      },
      {
        path: '/employees-report',
        name: 'common.mainMenu.employeesReport',
        role: roles.ROLE_SHOW_REPORT_EMPLOYEE_TIME_ENTRIES,
        component: EmployeesReport,
        layout: '/admin',
      },
      {
        path: '/billing-report',
        name: 'common.mainMenu.reportsBilling',
        role: roles.ROLE_SHOW_CLIENT_CALL,
        component: BillingReport,
        layout: '/admin',
      },
      {
        path: '/overproduction-report',
        name: 'common.mainMenu.reportsOverProduction',
        role: roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
        component: OverproductionReport,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'reports.accountant',
    icon: <AccountBalance />,
    views: [
      {
        path: '/income-accountant-report',
        name: 'reports.accountant.income',
        role: roles.ROLE_SHOW_REPORT_ACCOUNTANT_INCOME,
        component: AccountantReports.Income,
        layout: '/admin',
      },
      {
        path: '/deposit-and-refunds-accountant-report',
        name: 'reports.accountant.depositAndRefunds',
        role: roles.ROLE_SHOW_REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS,
        component: AccountantReports.DepositAndRefunds,
        layout: '/admin',
      },
      {
        path: '/new-accounts-accountant-report',
        name: 'reports.accountant.newAccounts',
        role: roles.ROLE_SHOW_REPORT_ACCOUNTANT_NEW_ACCOUNTS,
        component: AccountantReports.NewAccounts,
        layout: '/admin',
      },

      {
        path: '/disposal-accountant-report',
        name: 'common.mainMenu.reportsDisposal',
        role: roles.ROLE_SHOW_REPORT_DISPOSAL,
        component: Disposal,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.cateringsFlow',
    icon: <CheckBox />,
    views: [
      {
        path: '/tasks-generator',
        name: 'common.mainMenu.tasksGenerator',
        component: TasksGenerator,
        role: roles.ROLE_SHOW_TASKS_GENERATOR,
        layout: '/admin',
      },
      {
        path: '/kitchen',
        name: 'common.mainMenu.kitchen',
        component: Kitchen,
        role: roles.ROLE_SHOW_KITCHEN,
        layout: '/admin',
      },
      {
        path: '/dishes-packhouse',
        name: 'common.mainMenu.dishesPackhouse',
        component: DishesPackhouse,
        role: roles.ROLE_SHOW_DISH_PACKHOUSE,
        layout: '/admin',
      },
      {
        path: '/bags-packhouse',
        name: 'common.mainMenu.bagsPackhouse',
        component: BagsPackhouse,
        role: roles.ROLE_SHOW_BAGS_PACKHOUSE,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.myCompany',
    icon: <Settings />,
    views: [
      {
        path: '/company',
        name: 'common.mainMenu.myCompanySettings',
        component: MyCompanyRouter,
        role: roles.ROLE_EDIT_COMPANY,
        layout: '/admin',
      },
      {
        path: '/brands',
        name: 'common.mainMenu.myCompanyBrands',
        component: Brands,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
        componentProps: {
          valueUrl: '/brands',
        },
      },
      {
        path: '/roles',
        name: 'common.mainMenu.myCompanyRoles',
        component: Roles,
        role: roles.ROLE_SHOW_ROLE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/roles',
        },
      },
      {
        path: '/employees',
        name: 'common.mainMenu.myCompanyEmployees',
        component: Employees,
        role: roles.ROLE_SHOW_EMPLOYEE,
        layout: '/admin',
        componentProps: {
          valueUrl: '/employees',
        },
      },
      {
        path: '/report-archive',
        name: 'common.mainMenu.archive',
        component: Archive,
        role: roles.ROLE_SHOW_REPORT_ARCHIVE,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.myCompanyActiveBrand',
    icon: <Tune />,
    views: [
      {
        path: '/copy-brand-settings',
        name: 'common.mainMenu.brandConfiguration.copySettings',
        component: CopyingBrandSettings,
        role: roles.ROLE_SHOW_COPY_BRAND_CONFIGURATION,
        layout: '/admin',
      },
      {
        path: '/active-brand',
        name: 'common.mainMenu.brandConfiguration.information',
        component: ActiveBrandInformation,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
        componentProps: {
          valueUrl: '/active-brand',
        },
      },
      {
        path: '/client-panel-design',
        name: 'common.mainMenu.brandConfiguration.generalAppearance',
        component: ClientPanelDesign,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/order-form',
        name: 'common.mainMenu.brandConfiguration.orderForm',
        component: OrderForm,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
        breadcrumbAdditionalContent: (
          <ConfigurationOrderFormBreadcrumbAdditionalContent />
        ),
      },
      {
        path: '/panel-configuration',
        name: 'common.mainMenu.brandConfiguration.panelClient',
        component: PanelConfiguration,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/shop',
        name: 'common.mainMenu.shop',
        component: Shop,
        role: roles.ROLE_SHOW_MODULE_SHOP,
        layout: '/admin',
      },
      {
        path: '/payment-methods',
        name: 'common.mainMenu.brandConfiguration.paymentMethods',
        component: PaymentMethods,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
        breadcrumbAdditionalContent: (
          <ConfigurationPaymentMethodBreadcrumbAdditionalContent />
        ),
      },
      {
        path: '/deliveries-and-orders',
        name: 'common.mainMenu.brandConfiguration.deliveriesAndOrder',
        component: DeliveriesAndOrders,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/delivery-options',
        name: 'common.mainMenu.brandConfiguration.deliveryOptions',
        component: DeliveryOptions,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/login-options',
        name: 'common.mainMenu.brandConfiguration.loginOptions',
        component: LoginOptions,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/eco-containers-settings',
        name: 'common.mainMenu.brandConfiguration.ecoContainers',
        component: EcoContainers,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/email-and-sms',
        name: 'common.mainMenu.brandConfiguration.mailAndSms',
        component: MailAndSms,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/mail-notifications',
        name: 'common.mainMenu.myCompanyMailNotifications',
        component: MailNotifications,
        role: roles.ROLE_SHOW_MAIL_CONFIGURATION,
        layout: '/admin',
        breadcrumbAdditionalContent: (
          <MailNotificationsBreadcrumbAdditionalContent />
        ),
      },
      {
        path: '/sms-notifications',
        name: 'common.mainMenu.myCompanySmsNotification',
        component: SmsNotifications,
        role: roles.ROLE_SHOW_SMS_CONFIGURATION,
        layout: '/admin',
      },
      {
        path: '/mail-reminders',
        name: 'common.mainMenu.myCompanyMailReminders',
        component: MailReminders,
        breadcrumbAdditionalContent: (
          <MailRemindersBreadcrumbAdditionalContent />
        ),
        role: roles.ROLE_SHOW_END_OF_DIET_REMINDER,
        layout: '/admin',
      },
      {
        path: '/sms-reminders',
        name: 'common.mainMenu.myCompanySmsReminders',
        component: SmsReminders,
        role: roles.ROLE_SHOW_END_OF_DIET_REMINDER,
        layout: '/admin',
      },
      {
        path: '/accounting',
        name: 'common.mainMenu.brandConfiguration.accounting',
        component: Accounting,
        role: roles.ROLE_SHOW_BRAND,
        layout: '/admin',
      },
      {
        path: '/power-bi',
        name: 'common.mainMenu.brandConfiguration.powerBiLabel',
        component: PowerBI,
        role: roles.ROLE_SHOW_BRAND_POWER_BI,
        layout: '/admin',
      },
      {
        path: '/access-keys',
        name: 'brandCfg.accessKeysLabel',
        component: AccessKeys,
        role: roles.ROLE_SHOW_BRAND_ACCESS_KEY,
        layout: '/admin',
      },
      {
        path: '/product-feed',
        name: 'brandCfg.productFeedLabel',
        component: ProductFeed,
        role: roles.ROLE_SHOW_FEED,
        layout: '/admin',
      },
    ],
  },
  {
    icon: <CalculateIcon />,
    path: '/settlements-settings',
    name: 'common.mainMenu.settlements',
    component: SettlementsSettings,
    role: roles.ROLE_SHOW_MODULE_SETTLEMENTS,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'CMS',
    icon: <ColorLens />,
    views: [
      {
        path: '/cms-home',
        name: 'common.mainMenu.CMS.homePage',
        component: HomeCmsPage,
        role: roles.ROLE_EDIT_HOME_PAGE,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-home',
        },
      },
      {
        path: '/cms-addons',
        name: 'common.mainMenu.CMS.addons',
        component: AddonsCmsPage,
        role: roles.ROLE_EDIT_CMS_ADDON,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-addons',
        },
      },
      {
        path: '/cms-diets',
        name: 'common.mainMenu.CMS.diets',
        component: DietsCmsPage,
        role: roles.ROLE_EDIT_CMS_DIET,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-diets',
        },
      },
      {
        path: '/cms-meals',
        name: 'common.mainMenu.CMS.dishes',
        component: MealsCmsPage,
        role: roles.ROLE_EDIT_CMS_DISH,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-meals',
        },
      },
      {
        path: '/cms-zones',
        name: 'common.mainMenu.CMS.zone',
        component: ZonesCmsPage,
        role: roles.ROLE_EDIT_CMS_ZONE,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/cms-zones',
        },
      },
      {
        path: '/subpage-menu',
        name: 'common.mainMenu.subpageMenu',
        component: SubMenuWrapper(SubMenu),
        role: roles.ROLE_EDIT_CMS_MENU,
        layout: '/admin',
      },
      {
        path: '/blog',
        name: 'common.mainMenu.blog',
        component: BlogCreator,
        role: roles.ROLE_SHOW_BLOG_POST,
        layout: '/admin',
        componentProps: {
          tableName: 'Blog',
          tableTitle: '',
          valueUrl: '/blog',
        },
      },
      {
        path: '/pages-creator',
        name: 'common.mainMenu.myCompanyPagesCreator',
        component: PagesCreator,
        role: roles.ROLE_SHOW_PAGE,
        layout: '/admin',
        componentProps: {
          tableName: 'Podstrony',
          tableTitle: '',
          valueUrl: '/pages-creator',
        },
      },
    ],
  },
  {
    collapse: true,
    name: 'common.mainMenu.translations',
    icon: <FlagIcon />,
    views: [
      {
        path: '/admin-panel',
        name: 'common.mainMenu.adminPanel',
        component: TranslationsAdminPanel,
        role: roles.ROLE_SHOW_TRANSLATION,
        layout: '/admin',
      },
      {
        path: '/client-panel',
        name: 'common.mainMenu.clientPanel',
        component: TranslationsClientPanel,
        role: roles.ROLE_SHOW_TRANSLATION,
        layout: '/admin',
      },
      {
        path: '/api',
        name: 'API',
        component: TranslationsAPI,
        role: roles.ROLE_SHOW_TRANSLATION,
        layout: '/admin',
      },
      {
        path: '/language-settings',
        name: 'common.mainMenu.languageSettings',
        component: LanguageSettings,
        role: roles.ROLE_EDIT_TRANSLATION,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/analytics-tools',
    name: 'common.mainMenu.brandConfiguration.integrations',
    component: AnalyticsTools,
    icon: <GraphicEq />,
    role: roles.ROLE_SHOW_BRAND,
    layout: '/admin',
  },
  {
    path: '/additional-offer',
    name: 'common.mainMenu.navLinks.additionalOffer',
    icon: <AddCircleOutline />,
    component: AdditionalOfferPage,
    role: roles.ROLE_SHOW_ADDITIONAL_OFFER,
    componentProps: {
      custom: true,
    },
    layout: '/admin',
  },
];

export default routes;
