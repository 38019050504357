import { Router, Route, Switch } from 'react-router';

import List from './List';
import withRole from 'components/Security/withRole';
import {
  ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS,
  ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS,
} from 'helpers/roles';
import FormMassActionsContainer from './components/FormMassActions.container';

const MassActionDietElements = ({ history, match }) => {
  const ListSecured = withRole(ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS, List);
  const CreateForm = withRole(
    ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS,
    FormMassActionsContainer
  );

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={() => <ListSecured history={history} />}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <CreateForm history={history} />}
        />
      </Switch>
    </Router>
  );
};

export default MassActionDietElements;
