import { useTranslation } from 'react-i18next';
import { Extension } from '@material-ui/icons';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const Addons = () => {
  const { t } = useTranslation();

  const links = [
    {
      name: t('common.mainMenu.addonsSubmenu'),
      to: '/addons',
      additionalActivationPaths: getPathSuffixes('/addons', [
        'add',
        'edit/:id',
        'settings',
      ]),
    },
    {
      name: t('common.mainMenu.addonsCategories'),
      to: '/addon-categories',
      additionalActivationPaths: getPathSuffixes('/addon-categories', [
        'add',
        'edit/:id',
      ]),
    },
  ];

  return (
    <SidebarLinks
      name={t('common.mainMenu.addons')}
      icon={<Extension />}
      links={links}
    />
  );
};

export default Addons;
