import { useTranslation } from 'react-i18next';
import { PlaylistAddCheck } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const ProductionReports = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_EDIT_COMPANY) && {
      name: t('common.mainMenu.reportsGlobalConfig'),
      to: '/reports-configuration',
    },
    isGranted(roles.ROLE_SHOW_REPORT_SHOPPING) && {
      name: t('common.mainMenu.reportsShoppingList'),
      to: '/shopping-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_PURCHASED_ADDONS) && {
      name: t('common.mainMenu.reportsAddonsList.newTranslate'),
      to: '/purchased-addons-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_PURCHASED_ADDONS_BY_CLIENT) && {
      name: t('common.mainMenu.reportsAddonsByClientList'),
      to: '/client-purchased-addons-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_CONTAINERS) && {
      name: t('common.mainMenu.reportsContainers'),
      to: '/containers-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_CONTAINERS_DETAIL) && {
      name: t('common.mainMenu.reportsDetailedContainers'),
      to: '/detailed-containers-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_CHECKLIST_RECIPES) && {
      name: t('common.mainMenu.reportsRecipesChecklist'),
      to: '/checklist-recipes-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_RECIPES_CARD) && {
      name: t('common.mainMenu.reportsRecipeCards'),
      to: '/recipe-cards-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_RECIPES_INGREDIENTS) && {
      name: t('common.mainMenu.reportsRecipeIngredients'),
      to: '/recipe-ingredients-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_CHECKLIST_DISHES) && {
      name: t('common.mainMenu.reportsDishesChecklist'),
      to: '/checklist-dishes-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_DISHES_CARD) && {
      name: t('common.mainMenu.reportsDishCards'),
      to: '/dish-cards-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_DISH_STICKERS) && {
      name: t('common.mainMenu.reportsDishLabels'),
      to: '/dish-labels-report',
    },
    isGranted([
      roles.ROLE_SHOW_REPORT_DISH_STICKERS,
      roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
    ]) && {
      name: t('common.mainMenu.reportsDishLabelsAdditional'),
      to: '/overprod-dish-stickers-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_BAG_STICKERS_MINI) && {
      name: t('common.mainMenu.reportsBoxLabels-Mini'),
      to: '/mini-box-labels-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_DISH_LIST) && {
      name: t('common.mainMenu.reportsDishesToProduction'),
      to: '/dishes-to-production-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_CHECKLIST_DIETS) && {
      name: t('common.mainMenu.reportsDietsChecklist'),
      to: '/checklist-diets-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS) && {
      name: t('common.mainMenu.reportsAlteredDietsChecklist'),
      to: '/checklist-altered-diets-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_ACTIVE_DIET) && {
      name: t('common.mainMenu.reportActiveDiets'),
      to: '/active-diets-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_ACTIVE_DIET) && {
      name: t('common.mainMenu.reportActiveDietsTable'),
      to: '/table-active-diets-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_BAG_STICKERS) && {
      name: t('common.mainMenu.reportsBoxLabels'),
      to: '/box-labels-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_BAG_STICKERS) && {
      name: t('common.mainMenu.reportsBoxLabelsDateRange'),
      to: '/box-date-range-labels-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_PERSONAL_PICKUPS) && {
      name: t('common.mainMenu.reportPersonalPickups'),
      to: '/personal-pickups-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_PACKERS) && {
      name: t('common.mainMenu.reportPackers'),
      to: '/packers-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_FOR_DRIVER) && {
      name: t('common.mainMenu.reportsForDriver'),
      to: '/for-driver-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_RECIPE_STICKERS) && {
      name: t('common.mainMenu.reportRecipeStickers'),
      to: '/stickers-recipe-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_TOTAL_BEL) && {
      name: t('common.mainMenu.totalBelReport'),
      to: '/total-bel-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_ADVANCED_DISH_PDF) && {
      name: t('common.mainMenu.reportsPdfGenerator'),
      to: '/pdf-generator-report',
    },
    isGranted(roles.ROLE_SHOW_REPORT_SELECTIONS) && {
      name: t('common.mainMenu.reportsSelections'),
      to: '/selections-report',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.caterings.productions')}
      icon={<PlaylistAddCheck />}
      links={links}
    />
  );
};

export default ProductionReports;
