import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: { marginLeft: '5px' },
});

const DiscountCodeBreadcrumbAdditionalContent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <InfoWithTooltip
      className={classes.root}
      title={t('common.mainMenu.discounCode')}
      link={EXTERNAL_URLS.discountCode}
      size="m"
    />
  );
};

export default DiscountCodeBreadcrumbAdditionalContent;
