import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import { InputLabel, TextField } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import Button from 'components/CustomButtons/Button.jsx';

import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle';

const AmountOfDoneModal = ({
  isOpened = false,
  allowChangeAmountToMake = false,
  name = '',
  initialComment = '',
  initialAmountOfDone = 0,
  initialAmountToMake = 0,
  amountToMakeAfterRefreshedAmounts = 0,
  closeModal = () => {},
  handleSubmit = () => {},
}) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState(initialComment);
  const [amountOfDone, setAmountOfDone] = useState(
    initialAmountOfDone > 0 ? initialAmountOfDone : 0
  );
  const [amountToMake, setAmountToMake] = useState(initialAmountToMake ?? 0);

  useEffect(() => {
    setComment(initialComment ?? '');
    setAmountOfDone(initialAmountOfDone > 0 ? initialAmountOfDone : '');
    setAmountToMake(initialAmountToMake ?? 0);
  }, [initialComment, initialAmountOfDone]);

  const onNumberInputChange = (e, callback) => {
    const value = e.target.value;

    if (!Number.isInteger(Number(value)) || value < 0) {
      return;
    }

    callback(parseInt(value));
  };

  return (
    <Dialog maxWidth={'xs'} open={isOpened} onClose={closeModal} fullWidth>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: '40px' }}>
          <div style={{ display: 'inline-block' }}>
            <InputLabel htmlFor="countTodo">
              {t(
                '$*cateringsFlow.bagsPackhouse.amountOfDoneModal.amountToMake',
                '$$Ilość toreb do spakowania'
              )}
            </InputLabel>
            <TextField
              id="amountToMake"
              type="number"
              placeholder="0"
              value={amountToMakeAfterRefreshedAmounts || amountToMake}
              onChange={e => {
                onNumberInputChange(e, setAmountToMake);
              }}
              disabled={!allowChangeAmountToMake}
              size="small"
              variant="standard"
            />
          </div>
        </div>
        <div style={{ paddingBottom: '40px', display: 'flex' }}>
          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <InputLabel htmlFor="bagsComplete">
              {t(
                '$*cateringsFlow.bagsPackhouse.amountOfDoneModal.amountOfDone',
                '$$Torby kompletne'
              )}
            </InputLabel>
            <TextField
              id="bagsComplete"
              value={amountOfDone}
              placeholder="0"
              onChange={e => {
                onNumberInputChange(e, setAmountOfDone);
              }}
              size="small"
              type="number"
              variant="standard"
            />
          </div>
        </div>
        <div>
          <InputLabel htmlFor="note">
            {t(
              '$*cateringsFlow.bagsPackhouse.amountOfDoneModal.note',
              '$$Notatka'
            )}
          </InputLabel>
          <TextField
            id="note"
            value={comment}
            maxRows={5}
            variant="standard"
            onChange={e => setComment(e.target.value)}
            multiline
            fullWidth
          />
        </div>
      </DialogContent>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '10px',
        }}
      >
        <div style={{ padding: '0 10px' }}>
          <Button onClick={closeModal}>{t('form.close', '$$Zamknij')}</Button>
        </div>
        <div style={{ padding: '0 10px' }}>
          <Button
            color="success"
            onClick={() =>
              handleSubmit({
                amountOfDone: parseFloat(amountOfDone ?? 0),
                amountToMake: parseFloat(
                  amountToMakeAfterRefreshedAmounts
                    ? amountToMakeAfterRefreshedAmounts
                    : amountToMake
                ),
                comment,
              })
            }
          >
            {t('form.save', '$$Zapisz')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(customInputStyle)(AmountOfDoneModal);
