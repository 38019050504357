import { useTranslation } from 'react-i18next';
import { ColorLens } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const Cms = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_EDIT_HOME_PAGE) && {
      name: t('common.mainMenu.CMS.homePage'),
      to: '/cms-home',
    },
    isGranted(roles.ROLE_EDIT_CMS_ADDON) && {
      name: t('common.mainMenu.CMS.addons'),
      to: '/cms-addons',
    },
    isGranted(roles.ROLE_EDIT_CMS_DIET) && {
      name: t('common.mainMenu.CMS.diets'),
      to: '/cms-diets',
    },
    isGranted(roles.ROLE_EDIT_CMS_DISH) && {
      name: t('common.mainMenu.CMS.dishes'),
      to: '/cms-meals',
    },
    isGranted(roles.ROLE_EDIT_CMS_ZONE) && {
      name: t('common.mainMenu.CMS.zone'),
      to: '/cms-zones',
    },
    isGranted(roles.ROLE_EDIT_CMS_MENU) && {
      name: t('common.mainMenu.subpageMenu'),
      to: '/subpage-menu',
    },
    isGranted(roles.ROLE_SHOW_BLOG_POST) && {
      name: t('common.mainMenu.blog'),
      to: '/blog',
      additionalActivationPaths: getPathSuffixes('/blog', ['add', 'edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_PAGE) && {
      name: t('common.mainMenu.myCompanyPagesCreator'),
      to: '/pages-creator',
      additionalActivationPaths: getPathSuffixes('/pages-creator', [
        'add',
        'edit/:id',
      ]),
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.CMS.name')}
      icon={<ColorLens />}
      links={links}
    />
  );
};

export default Cms;
