import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles({
  container: {
    padding: '5px 0px',
    margin: '0',
    position: 'relative',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
});

const SidebarItem = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, className)}>{children}</div>
  );
};

SidebarItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SidebarItem;
