import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useMediaQuery, useTheme } from '@mui/material';
import { Drawer, Hidden } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { changeSidebarStatus, resetSidebarStatus } from 'actions/SidebarStatus';
import {
  blackColor,
  drawerMiniWidth,
  drawerWidth,
  hexToRgb,
  navyColor,
  transition,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react';

import UserBar from './UserBar/UserBar';
import BrandBar from './BrandBar/BrandBar';
import SidebarMobileLinks from './SidebarMobileLinks/SidebarMobileLinks';
import LinksBar from './LinksBar/LinksBar';

const useStyles = makeStyles({
  drawerPaper: ({ theme }) => ({
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transition: 'top .2s linear, bottom .2s linear, width .35s ease',
    overflow: 'hidden',
    boxShadow: `0 10px 30px -12px rgba(${hexToRgb(
      blackColor
    )}, 0.42), 0 4px 25px 0px rgba(${hexToRgb(
      blackColor
    )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
    width: drawerWidth,
    height: '100%',
    color: whiteColor,
    backgroundColor: navyColor,
    '&:after': {
      backgroundColor: navyColor,
    },
    [theme.breakpoints.down(960)]: {
      ...transition,
      right: '0',
      left: 'auto',
      visibility: 'visible',
      borderTop: 'none',
      paddingRight: '0',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
    },
    '&:before, &:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  }),
  drawerPaperMini: ({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      width: drawerMiniWidth,
    },
  }),
});

const Sidebar = ({ isMobileDrawerOpen, logoText, handleDrawerToggle }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();

  const { isSidebarMiniModeActive, isSidebarHovered } = useSelector(
    state => state.SidebarStatus
  );

  const isWiderWidth = useMediaQuery('(min-width:960px)');

  const paperClasses = {
    paper: classNames(classes.drawerPaper, {
      [classes.drawerPaperMini]: isSidebarMiniModeActive && !isSidebarHovered,
    }),
  };

  const changeSidebarHovered = isHovered => {
    dispatch(
      changeSidebarStatus({
        isSidebarHovered: isHovered,
      })
    );
  };

  useEffect(() => {
    dispatch(resetSidebarStatus());
  }, [isWiderWidth]);

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="right"
          variant="temporary"
          open={isMobileDrawerOpen}
          classes={paperClasses}
          onClose={handleDrawerToggle}
        >
          <BrandBar logoText={logoText} isMinimizeButtonShown={false} />
          <UserBar />
          <Hidden smUp implementation="css">
            <SidebarMobileLinks />
          </Hidden>
          <LinksBar />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={paperClasses}
          onMouseOver={() => {
            changeSidebarHovered(true);
          }}
          onMouseLeave={() => {
            changeSidebarHovered(false);
          }}
        >
          <BrandBar logoText={logoText} isMinimizeButtonShown={true} />
          <UserBar />
          <LinksBar />
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Sidebar;
