import { useTranslation } from 'react-i18next';
import { Tune } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const ConfigureBrand = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_COPY_BRAND_CONFIGURATION) && {
      name: t('common.mainMenu.brandConfiguration.copySettings'),
      to: '/copy-brand-settings',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.information'),
      to: '/active-brand',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.generalAppearance'),
      to: '/client-panel-design',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.orderForm'),
      to: '/order-form',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.panelClient'),
      to: '/panel-configuration',
    },
    isGranted(roles.ROLE_SHOW_MODULE_SHOP) && {
      name: t('common.mainMenu.shop'),
      to: '/shop',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.paymentMethods'),
      to: '/payment-methods',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.deliveriesAndOrder'),
      to: '/deliveries-and-orders',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.deliveryOptions'),
      to: '/delivery-options',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.loginOptions'),
      to: '/login-options',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.ecoContainers'),
      to: '/eco-containers-settings',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.mailAndSms'),
      to: '/email-and-sms',
    },
    isGranted(roles.ROLE_SHOW_MAIL_CONFIGURATION) && {
      name: t('common.mainMenu.myCompanyMailNotifications'),
      to: '/mail-notifications',
    },
    isGranted(roles.ROLE_SHOW_SMS_CONFIGURATION) && {
      name: t('common.mainMenu.myCompanySmsNotification'),
      to: '/sms-notifications',
    },
    isGranted(roles.ROLE_SHOW_END_OF_DIET_REMINDER) && {
      name: t('common.mainMenu.myCompanyMailReminders'),
      to: '/mail-reminders',
    },
    isGranted(roles.ROLE_SHOW_END_OF_DIET_REMINDER) && {
      name: t('common.mainMenu.myCompanySmsReminders'),
      to: '/sms-reminders',
    },
    isGranted(roles.ROLE_SHOW_BRAND) && {
      name: t('common.mainMenu.brandConfiguration.accounting'),
      to: '/accounting',
    },
    isGranted(roles.ROLE_SHOW_BRAND_POWER_BI) && {
      name: t('common.mainMenu.brandConfiguration.powerBiLabel'),
      to: '/power-bi',
    },
    isGranted(roles.ROLE_SHOW_BRAND_ACCESS_KEY) && {
      name: t('brandCfg.accessKeysLabel'),
      to: '/access-keys',
      additionalActivationPaths: getPathSuffixes('/access-keys', ['edit/:id']),
    },
    isGranted(roles.ROLE_SHOW_FEED) && {
      name: t('brandCfg.productFeedLabel'),
      to: '/product-feed',
      additionalActivationPaths: getPathSuffixes('/product-feed', [
        'add',
        'edit/:id',
      ]),
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.myCompanyActiveBrand')}
      icon={<Tune />}
      links={links}
    />
  );
};

export default ConfigureBrand;
