import { useTranslation } from 'react-i18next';
import { LocalPlay } from '@material-ui/icons';

import SidebarLink from '../../SidebarLink/SidebarLink';
import getPathSuffixes from '../helpers/getPathSuffixes';

const DiscountCodes = () => {
  const { t } = useTranslation();

  return (
    <SidebarLink
      name={t('common.mainMenu.discountCodes')}
      to="/discount-codes"
      additionalActivationPaths={getPathSuffixes('/discount-codes', [
        'add',
        'edit/:id',
        'preview/:id',
      ])}
      icon={<LocalPlay />}
    />
  );
};

export default DiscountCodes;
