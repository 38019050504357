import { Router, Route, Switch } from 'react-router';

import withRole from 'components/Security/withRole';
import { ROLE_CREATE_BRAND, ROLE_EDIT_BRAND } from 'helpers/roles';

import BrandSettings from '../BrandConfiguration/BrandSettings';
import MyCompany from './MyCompany';

const MyCompanyRouter = ({ history, match }) => {
  const Company = withRole(ROLE_EDIT_BRAND, MyCompany);
  const CreateForm = withRole(ROLE_CREATE_BRAND, BrandSettings);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={() => <Company history={history} />}
        />
        <Route
          path={`${match.url}/brands/add`}
          render={() => <CreateForm isEdit={false} />}
        />
      </Switch>
    </Router>
  );
};

export default MyCompanyRouter;
