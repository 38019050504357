import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { whiteColor } from 'assets/jss/material-dashboard-pro-react';

import NavLinkBase from '../../NavLinkBase/NavLinkBase';
import LINK_PROP_TYPE from '../constants/linkPropType';

const NESTED_LINE_OFFSET = '-20px';

const useStyles = makeStyles({
  list: {
    display: 'grid',
    rowGap: '10px',
    padding: '0',
    '&:before': {
      content: '""',
      display: 'block',
      width: '1px',
      height: 'calc(100% - 20px)',
      backgroundColor: whiteColor,
      position: 'absolute',
      top: '-5px',
      left: NESTED_LINE_OFFSET,
    },
  },
  listItem: {
    padding: '0',
  },
  navLink: {
    width: '100%',
    padding: '9px 10px',
    wordBreak: 'break-word',
    '&:before': {
      content: '""',
      display: 'block',
      width: '15px',
      height: '10px',
      position: 'absolute',
      left: NESTED_LINE_OFFSET,
      borderLeft: `1px solid ${whiteColor}`,
      borderBottom: `1px solid ${whiteColor}`,
      borderBottomLeftRadius: '5px',
    },
  },
});

const Links = ({ className, name, links }) => {
  const classes = useStyles();

  return (
    <List className={classNames(classes.list, className)}>
      {links.map(({ name: linkName, to, additionalActivationPaths = [] }) => (
        <ListItem
          className={classes.listItem}
          key={`${name}-${linkName}-${to}`}
        >
          <NavLinkBase
            className={classes.navLink}
            to={to}
            additionalActivationPaths={additionalActivationPaths}
          >
            {linkName}
          </NavLinkBase>
        </ListItem>
      ))}
    </List>
  );
};

Links.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(LINK_PROP_TYPE).isRequired,
};

export default Links;
