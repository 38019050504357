import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Collapse, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ChevronIcon from 'assets/img/ChevronIcon';

import {
  LINK_FOCUSED_BG_COLOR,
  LINK_BORDER_RADIUS,
} from '../../constants/styles';
import SidebarLinkIcon from '../SidebarLinkIcon/SidebarLinkIcon';
import isLinkActive from '../helpers/isLinkActive';
import Links from './Links/Links';
import LINK_PROP_TYPE from './constants/linkPropType';

const useStyles = makeStyles({
  listItem: {
    display: 'block',
    padding: '0',
  },
  expandButton: {
    transition: 'all 150ms ease-in',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '6px 10px 6px 13px',
    fontFamily: 'inherit',
    wordBreak: 'break-word',
    color: 'inherit',
    borderRadius: LINK_BORDER_RADIUS,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: LINK_FOCUSED_BG_COLOR,
    },
  },
  expandButtonActive: {
    backgroundColor: LINK_FOCUSED_BG_COLOR,
  },
  expandButtonMini: {
    justifyContent: 'center',
    padding: '3px 0',
  },
  name: {
    width: '100%',
    margin: '0 12px 0 18px',
    fontSize: '12px',
    fontWeight: '400',
    textAlign: 'left',
  },
  arrowIcon: {
    width: '12px',
    flexShrink: '0',
    transition: 'all 150ms ease-in',
  },
  arrowIconExpanded: {
    transform: 'rotate(180deg)',
  },
  links: {
    margin: '10px 0 0 43px',
  },
  linksMini: {
    margin: '0',
  },
  linksExpanded: {
    marginBottom: '5px',
  },
});

const SidebarLinks = ({ name, icon, links }) => {
  const classes = useStyles();

  const location = useLocation();

  const { isSidebarMiniModeActive, isSidebarHovered } = useSelector(
    state => state.SidebarStatus
  );

  const isPathActive = links.some(({ to, additionalActivationPaths = [] }) =>
    [to, ...additionalActivationPaths].some(activationPath =>
      isLinkActive(location.pathname, activationPath)
    )
  );

  const [isExpanded, setIsExpanded] = useState(isPathActive);

  return (
    <ListItem className={classes.listItem}>
      <button
        className={classNames(classes.expandButton, {
          [classes.expandButtonActive]: isPathActive,
          [classes.expandButtonMini]:
            isSidebarMiniModeActive && !isSidebarHovered,
        })}
        type="button"
        onClick={() => {
          setIsExpanded(prevIsExpanded => !prevIsExpanded);
        }}
      >
        <SidebarLinkIcon icon={icon} />
        {(!isSidebarMiniModeActive || isSidebarHovered) && (
          <>
            <span className={classes.name}>{name}</span>
            <ChevronIcon
              className={classNames(classes.arrowIcon, {
                [classes.arrowIconExpanded]: isExpanded,
              })}
            />
          </>
        )}
      </button>
      <Collapse
        in={isExpanded && (!isSidebarMiniModeActive || isSidebarHovered)}
        timeout={
          isSidebarMiniModeActive && !isSidebarHovered ? { exit: 0 } : undefined
        }
        unmountOnExit
      >
        <Links
          className={classNames(classes.links, {
            [classes.linksExpanded]: isExpanded,
            [classes.linksMini]: isSidebarMiniModeActive && !isSidebarHovered,
          })}
          name={name}
          links={links}
        />
      </Collapse>
    </ListItem>
  );
};

SidebarLinks.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  links: PropTypes.arrayOf(LINK_PROP_TYPE).isRequired,
};

export default SidebarLinks;
