import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';
import EXTERNAL_URLS from 'constants/externalUrls';
import { useTranslation } from 'react-i18next';

const ConfigurationOrderFormBreadcrumbAdditionalContent = () => {
  const { t } = useTranslation();

  return (
    <InfoWithTooltip
      title={t('common.mainMenu.configurationOrderForm')}
      link={EXTERNAL_URLS.configurationOrderForm}
      size="m"
    />
  );
};

export default ConfigurationOrderFormBreadcrumbAdditionalContent;
