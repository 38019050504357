import { Router, Route, Switch } from 'react-router';

import NavTabs from 'components/NavTabs/NavTabs';
import SettingsKwgGroup from './GroupOfFinishedProducts/SettingsKwgGroup/SettingsKwgGroup';
import { useTranslation } from 'react-i18next';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_KWG_GROUP,
  ROLE_SHOW_KWG_GROUP,
  ROLE_EDIT_KWG_GROUP,
} from 'helpers/roles';

import GroupOfFinishedProductsList from 'views/MealsNew/GroupOfFinishedProducts/List/GroupOfFinishedProductsList';
import GroupOfFinishedProductsForm from 'views/MealsNew/GroupOfFinishedProducts/Form/GroupOfFinishedProductsForm';

const GroupOfFinishedProductRoute = ({ props }) => {
  const { t } = useTranslation();

  const ListSecured = withRole(
    ROLE_SHOW_KWG_GROUP,
    GroupOfFinishedProductsList
  );

  const CreateForm = withRole(
    ROLE_CREATE_KWG_GROUP,
    GroupOfFinishedProductsForm
  );
  const EditForm = withRole(ROLE_EDIT_KWG_GROUP, GroupOfFinishedProductsForm);

  return (
    <NavTabs
      tabs={[
        {
          tabButton: t('views.dishes.collectiveSettings'),
          tabContent: <SettingsKwgGroup />,
        },
        {
          tabButton: t('views.dishes.groupOfFinishedProducts'),
          tabContent: (
            <Router history={props.history}>
              <Switch>
                <Route
                  exact
                  path={`${props.match.url}/kwg-groups`}
                  render={parentProps => (
                    <ListSecured {...props} {...parentProps} />
                  )}
                />
                <Route
                  path={`${props.match.url}/kwg-groups/add`}
                  render={() => <CreateForm {...props} />}
                />
                <Route
                  path={`${props.match.url}/kwg-groups/edit/:id`}
                  render={parentProps => (
                    <EditForm {...props} {...parentProps} />
                  )}
                />
              </Switch>
            </Router>
          ),
        },
      ]}
    />
  );
};

export default GroupOfFinishedProductRoute;
