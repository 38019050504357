import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    opacity: '0.8',
  },
});

const SidebarLinkIcon = ({ icon }) => {
  const classes = useStyles();

  return <span className={classes.icon}>{icon}</span>;
};

export default SidebarLinkIcon;
