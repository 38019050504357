import { Link } from 'react-router-dom';
import ListComponents from './ListComponents';
import StatusPicker from './StatusPicker';
import SourcePicker from './SourcePicker';
import VerifyButton from './VerifyButton';
import PaymentNeedPayButton from './PaymentNeedPayButton';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
import { translateOrderStatuses } from './statusTranslations';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import { Tooltip } from '@material-ui/core';
import { currencyToSymbol } from 'utils/currencies';
import { FormatListBulleted } from '@material-ui/icons';
import ItemTypeOrder from 'components/Grid/Filter/ItemTypeOrder';

export const paymentTypeOptions = [
  'paymentType.all',
  'paymentType.tPay',
  'paymentType.payPo',
  'paymentType.transfer',
  'paymentType.cash',
  'paymentType.payU',
  'paymentType.payUCard',
  'paymentType.payUBlik',
  'paymentType.payUGooglePay',
  'paymentType.stripeLink',
  'paymentType.stripeCard',
  'paymentType.blueMedia',
  'paymentType.blueMediaCard',
  'paymentType.blueMediaBlik',
  'paymentType.payNow',
  'paymentType.dietly',
  'paymentType.cateromarket',
  'paymentType.dine2door',
  'paymentType.groupon',
  'paymentType.transferBok',
  'paymentType.other',
];

export const paymentTypesTranslations = {
  TPAY: 'paymentType.tPay',
  PAYPO: 'paymentType.payPo',
  BANK_WIRE: 'paymentType.transfer',
  CASH: 'paymentType.cash',
  PAYU: 'paymentType.payU',
  PAYU_CARD: 'paymentType.payUCard',
  PAYU_BLIK: 'paymentType.payUBlik',
  PAYU_PAYPO: 'paymentType.payUPayPo',
  PAYU_GOOGLE_PAY: 'paymentType.payUGooglePay',
  PAYU_PAY_BY_LINKS: 'paymentType.payUPBL',
  STRIPE_LINK: 'paymentType.stripeLink',
  STRIPE_CARD: 'paymentType.stripeCard',
  BLUE_MEDIA: 'paymentType.blueMedia',
  BLUE_MEDIA_CARD: 'paymentType.blueMediaCard',
  BLUE_MEDIA_BLIK: 'paymentType.blueMediaBlik',
  PAYNOW: 'paymentType.payNow',
  DIETLY: 'paymentType.dietly',
  CATEROMARKET: 'paymentType.cateromarket',
  DINE2DOOR: 'paymentType.dine2door',
  GROUPON: 'paymentType.groupon',
  TRANSFER: 'paymentType.transferBok',
  OTHER: 'paymentType.other',
};

const columnConfig = (
  grid,
  fillModal,
  t,
  sources,
  selectDroprown,
  setSelectDropdown
) => {
  return [
    {
      title: 'ID',
      name: 'id',
      accessor: 'id',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'orders.isTest',
      accessor: row =>
        row?.isTest !== undefined ? <BooleanView value={row.isTest} /> : null,
      name: 'isTest',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.isClientsFirstOrder',
      accessor: row =>
        row?.isClientsFirstOrder !== undefined ? (
          <BooleanView value={row.isClientsFirstOrder} />
        ) : null,
      name: 'isClientsFirstOrder',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.verified',
      accessor: row =>
        row?.verified !== undefined ? (
          <VerifyButton verified={row.verified} orderId={row['@id']} />
        ) : null,
      name: 'verified',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.isInvoiceIncluded',
      accessor: row =>
        row?.isInvoiceIncluded !== undefined ? (
          <BooleanView value={row.isInvoiceIncluded} />
        ) : null,
      name: 'isInvoiceIncluded',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.dontSendToFakturownia',
      accessor: row =>
        row?.paymentNeedVerification !== undefined ? (
          <PaymentNeedPayButton
            paymentNeedVerification={row.paymentNeedVerification}
            orderId={row['@id']}
          />
        ) : null,
      name: 'paymentNeedVerification',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.fullname',
      accessor: row =>
        row?.client && (
          <Link to={`/admin/clients/edit/${row.client.id}`} target="_blank">
            {`${row.client.firstName} ${row.client.lastName}`}
          </Link>
        ),

      name: 'client.firstName',
      filterable: true,
      sortable: true,
    },
    {
      title: 'orders.email',
      accessor: 'client.email',
      name: 'client.email',
      sortable: false,
    },
    {
      title: 'orders.phone',
      accessor: row => {
        return (
          <a href={`tel:${row.client?.phone?.number}`}>
            {row.client?.phone?.number}
          </a>
        );
      },
      name: 'client.phone.number',
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.city',
      accessor: row => {
        if (!row.client || !row.client.defaultAddress) {
          return '';
        }

        return row.client.defaultAddress.city;
      },
      name: 'client.defaultAddress.city',
      sortable: false,
    },
    {
      title: 'addresses.zone',
      accessor: row => {
        return row?.client?.defaultAddress?.zone?.name;
      },
      name: 'client.defaultAddress.zone.name',
      sortable: false,
    },
    {
      title: 'orders.orderDate',
      Filter: ({ onChange, filter }) => {
        return <DateRangeFilter onChange={onChange} value={filter?.value} />;
      },
      accessor: row => {
        return row?.createdAt !== undefined
          ? dateTimeCell(row.createdAt)
          : null;
      },
      name: 'createdAt',
      filterable: true,
      sortable: true,
      width: 160,
    },
    {
      title: 'orders.source',
      name: 'source',
      accessor: row => {
        return row?.status ? (
          <SourcePicker
            initialValue={row.source?.['@id']}
            orderId={row['@id']}
            sources={sources}
          />
        ) : null;
      },
      width: 120,
      Filter: ({ onChange, filter }) => {
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{t('paymentType.all')}</option>
            {sources.map(({ '@id': iri, value }) => (
              <option value={iri}>{value}</option>
            ))}
          </select>
        );
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.items',
      accessor: row => {
        if (row.items === undefined) return;
        const v2types = [
          'OrderItemDayDish',
          'OrderItemDayAddon',
          'OrderItemDayDelivery',
          'OrderItemDayModification',
          'OrderItemDayDiet',
          'OrderItemDayMealType',
        ];

        const orderItemDiets = row.items.filter(
          el => el.type === 'OrderItemDiet'
        );

        const v2Items = row.items.filter(el => v2types.includes(el.type));

        if (orderItemDiets.length > 0 && v2Items?.length > 0) {
          const Diet = ListComponents['OrderItemDiet'];
          const V2Items = ListComponents['v2Types'];

          return (
            <>
              {orderItemDiets
                .map(el => (
                  <Diet orderId={row.id} item={el} fillModal={fillModal} />
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
              {', '}
              <V2Items items={v2Items} fillModal={fillModal} />
            </>
          );
        }

        if (v2Items?.length > 0) {
          const Component = ListComponents['v2Types'];

          return <Component items={v2Items} fillModal={fillModal} />;
        }

        if (orderItemDiets.length > 0) {
          const Component = ListComponents['OrderItemDiet'];
          return orderItemDiets
            .map(el => (
              <Component orderId={row.id} item={el} fillModal={fillModal} />
            ))
            .reduce((prev, curr) => [prev, ', ', curr]);
        }

        const diets = row.items.filter(el => el.type === 'DietItem');
        if (diets.length > 0) {
          const Component = ListComponents['DietItem'];

          return diets
            .map(el => (
              <Component orderId={row.id} item={el} fillModal={fillModal} />
            ))
            .reduce((prev, curr) => [prev, ', ', curr]);
        }

        if (row.items.length === 0) {
          return null;
        }

        const Component = ListComponents[row.items[0].type] || null;

        if (null === Component) {
          return `Unknown type "${row.items[0].type}"`;
        }

        return (
          <Component
            orderId={row.id}
            fillModal={fillModal}
            items={row.items}
            item={row.items[0]}
            userId={row.client.id}
          />
        );
      },
      name: 'items.diet.diet.name',
      width: 250,
      filterable: true,
      sortable: false,
      Filter: ({ onChange, filter }) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              {selectDroprown ? (
                <ItemTypeOrder onChange={onChange} value={`${filter?.value}`} />
              ) : (
                <>
                  <input
                    value={filter?.value}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                </>
              )}
              <Tooltip
                title={<h4>{t('common.orderFilter.tooltip')}</h4>}
                placement="top"
              >
                <FormatListBulleted
                  style={{ marginRight: '5px' }}
                  fontSize="small"
                  onClick={() => {
                    setSelectDropdown();
                    onChange('');
                  }}
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      title: 'orders.value',
      accessor: row => row.priceAfterDiscountWithoutMoneyBox.toFixed(2),
      name: 'priceAfterDiscountWithoutMoneyBox',
      width: 120,
      Filter: ({ onChange, filter }) => {
        return (
          <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
        );
      },
      filterable: true,
      sortable: false,
      summarize: true,
    },
    {
      title: 'orders.pointsFromMoneyBox',
      accessor: 'pointsFromMoneyBox',
      width: 100,
      Filter: ({ onChange, filter }) => {
        return (
          <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
        );
      },
      filterable: true,
      sortable: true,
    },
    {
      name: 'discountCode.code',
      title: 'orders.discountCode',
      accessor: (row, plug) => {
        return row?.discountCode !== undefined ? (
          row.discountCode !== null ? (
            <Tooltip
              title={
                <h4>
                  {row.discountCode.discount}{' '}
                  {!row.discountCode.isPercentage
                    ? currencyToSymbol(plug.multinational.currency)
                    : '%'}
                </h4>
              }
              placement="top"
            >
              <span>{row.discountCode.code}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ) : null;
      },
      filterable: true,
      sortable: true,
    },
    {
      title: 'orders.status',
      accessor: row => {
        return row?.status ? (
          <StatusPicker
            value={row.status}
            disabled={row.status.systemValue === 'CANCELED'}
            orderId={row['@id']}
            grid={grid}
          />
        ) : null;
      },
      filterable: true,
      sortable: false,
      name: 'status.systemValue',
      width: 200,
      Filter: ({ onChange, filter }) => {
        const selectOptions = translateOrderStatuses(t);
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{selectOptions[0].label}</option>
            <option value={selectOptions[1].systemValue}>
              {selectOptions[1].label}
            </option>
            <option value={selectOptions[2].systemValue}>
              {selectOptions[2].label}
            </option>
            <option value={selectOptions[3].systemValue}>
              {selectOptions[3].label}
            </option>
          </select>
        );
      },
    },
    {
      title: 'orders.paymentType',
      accessor: row =>
        row?.paymentType !== undefined
          ? t(paymentTypesTranslations[row.paymentType])
          : null,
      sortable: false,
      filterable: true,
      name: 'paymentType',
      Filter: ({ onChange, filter }) => {
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{t(paymentTypeOptions[0])}</option>
            {Object.entries(paymentTypesTranslations).map(([key, value]) => (
              <option value={key}>{t(value)}</option>
            ))}
          </select>
        );
      },
    },
  ];
};

export default columnConfig;
