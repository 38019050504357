import types from 'helpers/constants';

export const changeSidebarStatus = payload => dispatch =>
  dispatch({
    type: types.UPDATE_SIDEBAR_STATUS,
    payload,
  });

export const resetSidebarStatus = () => dispatch =>
  dispatch({
    type: types.RESET_SIDEBAR_STATUS,
  });
