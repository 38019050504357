import { useTranslation } from 'react-i18next';
import { Announcement } from '@material-ui/icons';

import SidebarLink from '../../SidebarLink/SidebarLink';
import getPathSuffixes from '../helpers/getPathSuffixes';

const Announcements = () => {
  const { t } = useTranslation();

  return (
    <SidebarLink
      name={t('common.mainMenu.messages')}
      to="/messages"
      additionalActivationPaths={getPathSuffixes('/messages', [
        'add',
        'edit/:id',
      ])}
      icon={<Announcement />}
    />
  );
};

export default Announcements;
