import { useTranslation } from 'react-i18next';
import { LocalDining } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';
import getPathSuffixes from '../helpers/getPathSuffixes';

const MenuPlanning = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_MENU_PLANNING) && {
      name: t('common.mainMenu.menu'),
      to: '/menu-planner',
      additionalActivationPaths: getPathSuffixes('/menu-planner', [':date']),
    },
    isGranted(roles.ROLE_SHOW_MENU_SUMMARY) && {
      name: t('common.mainMenu.menuSummary'),
      to: '/menu-summary',
    },
    isGranted(roles.ROLE_SHOW_MENU_SUMMARY) && {
      name: t('common.mainMenu.menuSummary.v2'),
      to: '/seccond-menu-summary',
      additionalActivationPaths: getPathSuffixes('/seccond-menu-summary', [
        ':date/:variant',
      ]),
    },
    isGranted(roles.ROLE_CREATE_MENU_PLANNING) && {
      name: t('$*common.mainMenu.menu.copying'),
      to: '/menu-copying',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.menuPlanner')}
      icon={<LocalDining />}
      links={links}
    />
  );
};

export default MenuPlanning;
