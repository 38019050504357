import { useTranslation } from 'react-i18next';
import { GraphicEq } from '@material-ui/icons';

import SidebarLink from '../../SidebarLink/SidebarLink';

const Integrations = () => {
  const { t } = useTranslation();

  return (
    <SidebarLink
      name={t('common.mainMenu.brandConfiguration.integrations')}
      to="/analytics-tools"
      icon={<GraphicEq />}
    />
  );
};

export default Integrations;
