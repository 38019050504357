import { useTranslation } from 'react-i18next';
import { Flag } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const Translations = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_TRANSLATION) && {
      name: t('common.mainMenu.adminPanel'),
      to: '/admin-panel',
    },
    isGranted(roles.ROLE_SHOW_TRANSLATION) && {
      name: t('common.mainMenu.clientPanel'),
      to: '/client-panel',
    },
    isGranted(roles.ROLE_SHOW_TRANSLATION) && {
      name: t('common.mainMenu.api'),
      to: '/api',
    },
    isGranted(roles.ROLE_EDIT_TRANSLATION) && {
      name: t('common.mainMenu.languageSettings'),
      to: '/language-settings',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.translations')}
      icon={<Flag />}
      links={links}
    />
  );
};

export default Translations;
