import { grayColor } from 'assets/jss/material-dashboard-pro-react';

const buttonsStyle = {
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px',
  },
  cardHeader: {
    zIndex: '3',
  },
  cardContentLeft: {
    padding: '15px 20px 15px 0px',
    position: 'relative',
  },
  cardContentRight: {
    padding: '15px 20px 15px 0px',
    position: 'relative',
  },
  cardContentBottom: {
    padding: '15px 0px 0px 0px',
    position: 'relative',
  },
  marginRight: {
    marginRight: '5px',
  },
  icons: {
    width: '17px',
    height: '17px',
  },
  buttonGroup: {
    position: 'relative',
    margin: '10px 1px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  firstButton: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    margin: '0',
    position: 'relative',
    float: 'left',
    '&:hover': {
      zIndex: '2',
    },
  },
  middleButton: {
    borderRadius: '0',
    margin: '0',
    position: 'relative',
    float: 'left',
    '&:hover': {
      zIndex: '2',
    },
  },
  lastButton: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    margin: '0',
    '&:hover': {
      zIndex: '2',
    },
  },
  socialButtonsIcons: {
    fontSize: '18px',
    marginTop: '-2px',
    position: 'relative',
  },
};

export default buttonsStyle;
