import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import EXTERNAL_URLS from 'constants/externalUrls';
import { getNumberFromString } from 'helpers/helpers';
import Danger from 'components/Typography/Danger';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import InfoWithTooltip from 'components/InfoWithTooltip/InfoWithTooltip';

const useStyles = makeStyles({
  info: {
    position: 'relative',
    left: '20px',
    fontSize: '10px',
    transform: 'scale(2)',
  },
});

const useGetColumns = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  return [
    {
      title: 'ID',
      accessor: 'id',
      name: 'id',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.active',
      accessor: row => <BooleanView value={row?.containsActiveDiets} />,
      name: 'isInUse',
      sortable: false,
      filterable: true,
      Filter: ({ onChange, filter }) => (
        <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
      ),
    },
    {
      title: 'addresses.userId',
      accessor: row =>
        row.user ? (
          <Link to={`/admin/clients/edit/${row.user.id}`} target="_blank">
            {row.user.id}
          </Link>
        ) : (
          <b>-</b>
        ),
      name: 'user.id',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.city',
      accessor: 'city',
      name: 'city',
      width: 200,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.postcode',
      accessor: 'postCode',
      name: 'postCode',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.address',
      accessor: 'street',
      name: 'street',
      width: 200,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.buildingNo',
      accessor: 'buildNumber',
      name: 'buildNumber',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.zone',
      headerAdditionalContent: (
        <InfoWithTooltip
          className={classes.info}
          link={EXTERNAL_URLS.whyCustomerAddressDoesntHaveAssignedZone}
          title={t('addresses.zoneInfo')}
          size="s"
        />
      ),
      accessor: row =>
        row.zone ? (
          <Link
            to={`/admin/zones/edit/${getNumberFromString(row.zone['@id'])}`}
            target="_blank"
          >
            {row.zone.name}
          </Link>
        ) : (
          <Danger>
            <b>{t('common.lack')}</b>
          </Danger>
        ),
      name: 'zone.name',
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.zoneShort',
      accessor: row =>
        row.zone ? (
          <Link
            to={`/admin/zones/edit/${getNumberFromString(row.zone['@id'])}`}
            target="_blank"
          >
            {row.zone.shortName}
          </Link>
        ) : (
          <b>-</b>
        ),
      name: 'zone.shortName',
      filterable: false,
      sortable: false,
    },
    {
      title: 'addresses.assignedDriver',
      accessor: row =>
        row.driver ? (
          <Link
            to={`/admin/drivers/edit/${getNumberFromString(row.driver['@id'])}`}
            target="_blank"
          >
            {row.driver.name}
          </Link>
        ) : (
          <Danger>
            <b>{t('common.lack')}</b>
          </Danger>
        ),
      name: 'driver.name',
      filterable: true,
      sortable: true,
      width: 200,
      Filter: ({ onChange, filter }) => (
        <input
          onChange={e => {
            onChange(e.target.value);
          }}
          value={filter?.value || ''}
        />
      ),
    },
  ];
};

export default useGetColumns;
