import React from 'react';
import styled from 'styled-components';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { DialogLoader } from 'components/DialogLoader';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { AddCircleOutline, AllOut, RemoveCircle } from '@material-ui/icons';
import Card from 'components/Card/Card';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import SelectInput from 'components/FormSelect/SelectInput';
import CardFooter from 'components/Card/CardFooter';
import CheckBoxForm from './CheckboxForm';
import CardWrapper from 'components/Card/CardWrapper';

const Wrapper = styled.div`
  h5 {
    font-size: 1rem;
  }
`;

const FormMassActionsView = ({ props }) => {
  const {
    t,
    classes,
    diets,
    state,
    handleToggle,
    handleChangeDate,
    handleSubmit,
    addMappingRow,
    handleDiets,
    handleVariants,
    handleCalorific,
    getDietVariants,
    getCountRowsWithDiet,
    getCountRowsWithVariantAndDiet,
    fillVariants,
    fillVariantCalories,
    removeRow,
  } = props;
  return (
    <Wrapper>
      <DialogLoader
        loaderState={state.isSubmitting}
        text={t('form.savingChanges')}
      />
      <CardWrapper title={t('massAction.dietElements.title')}>
        <CardBody style={{ paddingBottom: '3rem' }}>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <CheckBoxForm
                name={'applyToAllActiveDiets'}
                classes={classes}
                checked={state.applyToAllActiveDiets}
                handleToggle={handleToggle}
                label={t('massAction.dietElements.applyToAllActiveDiets')}
              />
            </GridItem>
            {!state.applyToAllActiveDiets && (
              <>
                <GridItem sm={4}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {t('massAction.dietElements.dateFrom')} *
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(state.dateFrom)}
                      onChange={ev => handleChangeDate(ev, 'dateFrom')}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem sm={4}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {t('massAction.dietElements.dateTo')} *
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(state.dateTo)}
                      onChange={ev => handleChangeDate(ev, 'dateTo')}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem sm={3}>
              <CheckBoxForm
                name={'updateBags'}
                classes={classes}
                checked={state.updateBags}
                handleToggle={handleToggle}
                label={t('massAction.dietElements.updateBags')}
                tooltip={t('massAction.dietElements.updateBags.tooltip')}
              />
              {state.updateBags && (
                <div style={{ paddingLeft: '25px' }}>
                  <CheckBoxForm
                    name={'updateDietDefaults'}
                    classes={classes}
                    checked={state.updateDietDefaults}
                    handleToggle={handleToggle}
                    label={t('massAction.dietElements.updateDietDefaults')}
                    tooltip={t(
                      'massAction.dietElements.updateDietDefaults.tooltip'
                    )}
                  />
                </div>
              )}
            </GridItem>
            <GridItem sm={3}>
              <CheckBoxForm
                name={'updateSubscriptions'}
                classes={classes}
                checked={state.updateSubscriptions}
                handleToggle={handleToggle}
                label={t('massAction.dietElements.updateSubscriptions')}
                tooltip={t(
                  'massAction.dietElements.updateSubscriptions.tooltip'
                )}
              />
              {state.updateSubscriptions && (
                <div style={{ paddingLeft: '25px' }}>
                  <CheckBoxForm
                    name={'resetSubscriptionIntents'}
                    classes={classes}
                    checked={state.resetSubscriptionIntents}
                    handleToggle={handleToggle}
                    label={t(
                      'massAction.dietElements.resetSubscriptionIntents'
                    )}
                    tooltip={t(
                      'massAction.dietElements.resetSubscriptionIntents.tooltip'
                    )}
                  />
                </div>
              )}
            </GridItem>
            {!state.resetSubscriptionIntents && (
              <GridItem sm={3}>
                <CheckBoxForm
                  name={'updateSubscriptionIntents'}
                  classes={classes}
                  checked={state.updateSubscriptionIntents}
                  handleToggle={handleToggle}
                  label={t('massAction.dietElements.updateSubscriptionIntents')}
                  tooltip={t(
                    'massAction.dietElements.updateSubscriptionIntents.tooltip'
                  )}
                />
              </GridItem>
            )}
          </GridContainer>
          <GridContainer></GridContainer>
        </CardBody>
      </CardWrapper>
      <Card>
        <CardBody>
          <Table style={{ textAlign: 'center' }}>
            <TableHead>
              <TableRow style={{ textAlign: 'center' }}>
                <TableCell
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.from')}
                </TableCell>
                <TableCell
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.to')}
                </TableCell>
              </TableRow>
              <TableRow style={{ textAlign: 'center' }}>
                <TableCell
                  colSpan={1}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.diet')}
                </TableCell>
                <TableCell
                  colSpan={1}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.variant')}
                </TableCell>
                <TableCell
                  colSpan={1}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.calorific')}
                </TableCell>
                <TableCell
                  colSpan={1}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.diet')}
                </TableCell>
                <TableCell
                  colSpan={1}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.variant')}
                </TableCell>
                <TableCell
                  colSpan={1}
                  style={{
                    textAlign: 'center',
                    border: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  {t('massAction.dietElements.calorific')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.mapping.map((mapping, index) => (
                <TableRow>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                      position: 'relative',
                    }}
                  >
                    <SelectInput
                      classes={classes}
                      mapBy="name"
                      trackBy="@id"
                      options={diets}
                      handleChange={(ev, obj) =>
                        handleDiets('from', index, ev, obj)
                      }
                      value={mapping.from.diet}
                    />
                    <RemoveCircle
                      onClick={() => removeRow(index)}
                      style={{
                        position: 'absolute',
                        left: -13,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                      position: 'relative',
                    }}
                  >
                    {mapping.from.diet && (
                      <>
                        <SelectInput
                          classes={classes}
                          mapBy="name"
                          trackBy="@id"
                          options={getDietVariants(mapping.from.diet)}
                          handleChange={(ev, obj) =>
                            handleVariants('from', index, ev, obj)
                          }
                          value={mapping.from.variant}
                        />
                        {getCountRowsWithDiet(mapping.from.diet) <= 1 && (
                          <>
                            <Button
                              onClick={() =>
                                fillVariants(index, mapping.from.diet)
                              }
                              style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                              }}
                            >
                              <AllOut />
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                      position: 'relative',
                    }}
                  >
                    {mapping.from.variant && (
                      <>
                        <SelectInput
                          noGrid={true}
                          classes={classes}
                          mapBy="name"
                          trackBy="@id"
                          options={mapping.from.variant.calories}
                          handleChange={(ev, obj) =>
                            handleCalorific('from', index, ev, obj)
                          }
                          value={mapping.from.calorific}
                        />
                        {getCountRowsWithVariantAndDiet(
                          mapping.from.diet,
                          mapping.from.variant
                        ) <= 1 && (
                          <>
                            <Button
                              onClick={() =>
                                fillVariantCalories(
                                  index,
                                  mapping.from.diet,
                                  mapping.from.variant
                                )
                              }
                              style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                              }}
                            >
                              <AllOut />
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    <SelectInput
                      classes={classes}
                      mapBy="name"
                      trackBy="@id"
                      options={diets}
                      handleChange={(ev, obj) =>
                        handleDiets('to', index, ev, obj)
                      }
                      value={mapping.to.diet}
                    />
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {mapping.to.diet && (
                      <SelectInput
                        classes={classes}
                        mapBy="name"
                        trackBy="@id"
                        options={getDietVariants(mapping.to.diet)}
                        handleChange={(ev, obj) =>
                          handleVariants('to', index, ev, obj)
                        }
                        value={mapping.to.variant}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {mapping.to.variant && (
                      <SelectInput
                        classes={classes}
                        mapBy="name"
                        trackBy="@id"
                        options={mapping.to.variant.calories}
                        handleChange={(ev, obj) =>
                          handleCalorific('to', index, ev, obj)
                        }
                        value={mapping.to.calorific}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <GridContainer justify="center">
            <Button onClick={addMappingRow}>
              {t('massAction.dietElements.addRow')} <AddCircleOutline />
            </Button>
          </GridContainer>
        </CardFooter>
      </Card>

      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/mass-actions-diet-elements"
        handleSubmit={handleSubmit}
      />
      {state.alert}
    </Wrapper>
  );
};

export default FormMassActionsView;
