import { useTranslation } from 'react-i18next';
import { ShoppingCart } from '@material-ui/icons';

import { isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';

import SidebarLinks from '../../SidebarLinks/SidebarLinks';

const PriceLists = () => {
  const { t } = useTranslation();

  const links = [
    isGranted(roles.ROLE_SHOW_COST) && {
      name: t('common.mainMenu.dietPrices'),
      to: '/prices',
    },
    isGranted(roles.ROLE_SHOW_COST) && {
      name: t('common.mainMenu.delivery'),
      to: '/delivery',
    },
    isGranted(roles.ROLE_SHOW_COST) && {
      name: t('common.mainMenu.discounts'),
      to: '/discounts',
    },
    isGranted(roles.ROLE_SHOW_COST) && {
      name: t('common.mainMenu.additionalPrices'),
      to: '/additionals',
    },
    isGranted(roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION) && {
      name: t('common.mainMenu.moneyBoxConfig'),
      to: '/money-box-config',
    },
  ].filter(Boolean);

  if (links.length === 0) {
    return null;
  }

  return (
    <SidebarLinks
      name={t('common.mainMenu.priceLists')}
      icon={<ShoppingCart />}
      links={links}
    />
  );
};

export default PriceLists;
