import Moment from 'moment';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Select from 'components/Grid/Filter/Select';
import DateFilter from 'components/Grid/Filter/Date';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import { Link } from 'react-router-dom';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 100,
    filterable: true,
    sortable: true,
  },
  {
    title: 'comments.commentDate',
    accessor: row => Moment(row.updatedAt).format('YYYY-MM-DD HH:mm'),
    name: 'createdAt',
    Filter: ({ onChange, filter }) => (
      <DateRangeFilter onChange={onChange} value={filter?.value} />
    ),
    width: 180,
    filterable: true,
    sortable: true,
  },
  {
    title: 'comments.user',
    accessor: row => {
      const client = row?.bag?.clientDiet?.client;

      return `${client?.firstName} ${client?.lastName}`;
    },
    name: 'user',
    width: 200,
    filterable: true,
    sortable: false,
  },
  {
    title: 'comments.deliveryDate',
    accessor: row => Moment(row?.bag?.date).format('YYYY-MM-DD'),
    name: 'date',
    width: 100,
    Filter: ({ onChange, filter }) => (
      <DateFilter onChange={onChange} value={filter?.value} />
    ),
    filterable: true,
    sortable: false,
  },
  {
    title: 'comments.diet',
    accessor: row => {
      if (row.type === 'MEAL_SHOP') {
        return t('comments.shop') + ' (#' + row?.bag?.clientDiet?.id + ')';
      }

      return row?.bag?.diet?.name;
    },
    name: 'diet',
    width: 150,
    filterable: true,
    sortable: false,
  },
  {
    title: 'comments.category',
    name: 'type',
    accessor: ({ type }) =>
      t(`comments.category.${type.toLowerCase()}`).toUpperCase(),
    filterable: true,
    Filter: ({ onChange, filter }) => {
      const options = [
        { '@id': 'DAY', name: t('comments.category.day') },
        { '@id': 'MEAL', name: t('comments.category.meal') },
        { '@id': 'DELIVERY', name: t('comments.category.delivery') },
        { '@id': 'MEAL_SHOP', name: t('comments.category.meal_shop') },
      ];

      return (
        <Select
          options={options}
          value={filter?.value}
          onChange={onChange}
          multiple={false}
        />
      );
    },
    width: 150,
    sortable: true,
  },
  {
    title: 'comments.dishId',
    accessor: row =>
      row.dishItem
        ? row?.dishItem?.dish?.id
        : row?.dishSizeItem?.dishSize?.dish?.id,
    name: 'dishItem.dish.id',
    width: 150,
    filterable: true,
    sortable: true,
  },
  {
    title: 'comments.dish',
    accessor: row => (
      <Link
        target="_blank"
        to={`/admin/meals/edit/${
          row.dishItem
            ? row?.dishItem?.dish?.id
            : row?.dishSizeItem?.dishSize?.dish?.id
        }`}
      >
        {row.dishItem
          ? row?.dishItem?.dish?.nameForClient
          : row?.dishSizeItem?.dishSize?.dish?.nameForClient}
      </Link>
    ),
    name: 'dishItem.dish.nameForClient',
    width: 150,
    filterable: true,
    extractFiltersByComma: false,
    sortable: true,
  },
  {
    title: 'comments.rate',
    accessor: row => {
      if (row.type === 'DAY') {
        return '';
      }
      let stars = [];
      let fullStars = row.rate;
      let borderedStars = 5 - row.rate;

      while (fullStars-- > 0) {
        stars.push(<Star style={{ color: '#DECF00' }} />);
      }

      while (borderedStars-- > 0) {
        stars.push(<StarBorder style={{ color: '#DECF00' }} />);
      }

      return (
        <div>
          {stars.map((el, key) => (
            <span key={key}>{el}</span>
          ))}
        </div>
      );
    },
    width: 130,
    name: 'rate',
    filterable: false,
    sortable: true,
  },
  {
    title: 'comments.content',
    accessor: 'comment',
    name: 'comment',
    filterable: true,
    sortable: true,
  },
];

export default columnConfig;
