import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';

import ArrowRightButtonIcon from 'assets/img/ArrowRightButtonIcon';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    padding: '0',
    backgroundColor: 'initial',
    color: 'currentColor',
    border: 'none',
  },
  buttonRotated: {
    transform: 'rotate(180deg)',
  },
});

const MinimizeButton = ({ isRotated = false, onClick }) => {
  const classes = useStyles();

  return (
    <button
      className={classNames(classes.button, {
        [classes.buttonRotated]: isRotated,
      })}
      type="button"
      onClick={onClick}
    >
      <ArrowRightButtonIcon />
    </button>
  );
};

export default MinimizeButton;
