import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@material-ui/styles';

import {
  exportDrawerWidth,
  exportDrawerMiniWidth,
} from 'assets/jss/material-dashboard-pro-react';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';

const useStyles = makeStyles({
  container: {
    bottom: '0px',
    height: '60px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
    boxShadow: '0px -5px 10px RGB(224,224,224)',
  },
  sticky: { position: 'sticky' },
  fixed: { position: 'fixed' },
  history: { fontWeight: 'bold', cursor: 'pointer', fontSize: '0.9rem' },
  marginButton: { margin: '5px' },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  marginZohoButton: { marginRight: '70px' },
});

const FormControlStickyButton = ({
  classes: propsClasses,
  cancelPath,
  submitText,
  discardText,
  submitColor,
  handleSubmit,
  submitDisabled,
  secondarySubmitText,
  secondarySubmitDisabled,
  handleSecondarySubmit,
  customButtonsLeft = [],
  customButtonsRight = [],
  dataCy,
  isSidebarMiniModeActive,
  history = false,
  showHistory,
  toggleHistory,
  t,
  zohoSalesIQ,
  customOffsetSmall = '',
  customOffsetLarge = '',
  isFixedToBottom = false,
}) => {
  const classes = useStyles();
  const isWiderWidth = useMediaQuery('(min-width:960px)');
  const drawerWidth = exportDrawerWidth;
  const drawerMiniWidth = exportDrawerMiniWidth;

  const calculateWidth = () => {
    const baseWidth = '100vw';
    const offsetSmall = customOffsetSmall ? customOffsetSmall : '15px';
    const offsetLarge = customOffsetLarge ? customOffsetLarge : '25px';

    if (!isWiderWidth) {
      return `calc(${baseWidth} - ${offsetSmall})`;
    }

    const sidebarOffset = isSidebarMiniModeActive
      ? drawerMiniWidth
      : drawerWidth;
    return `calc(${baseWidth} - ${sidebarOffset}px - ${offsetLarge})`;
  };

  return (
    <GridContainer
      className={`${classes.container} ${
        isFixedToBottom ? classes.fixed : classes.sticky
      }`}
      style={{ width: calculateWidth() }}
    >
      {history && (
        <GridItem sm={2} onClick={toggleHistory} className={classes.history}>
          {showHistory ? t('form.hideHistory') : t('form.showHistory')}
        </GridItem>
      )}
      <GridItem
        sm={history ? 10 : 12}
        className={classes.buttonContainer}
        data-cy={dataCy ? dataCy : '__buttons_add_and_cancel'}
      >
        {customButtonsLeft.map(({ label, ...props }) => (
          <Button {...props}>{label}</Button>
        ))}
        {cancelPath && (
          <Link to={cancelPath}>
            <Button
              className={`${propsClasses.marginRight} ${classes.marginButton}`}
            >
              {discardText}
            </Button>
          </Link>
        )}
        {secondarySubmitText && (
          <Button
            color={submitColor ? submitColor : 'success'}
            className={`${propsClasses.marginLeft} ${classes.marginButton}`}
            onClick={handleSecondarySubmit}
            disabled={secondarySubmitDisabled ? secondarySubmitDisabled : false}
          >
            {secondarySubmitText}
          </Button>
        )}
        {submitText && (
          <Button
            color={submitColor || 'success'}
            className={classNames(
              propsClasses.marginLeft,
              classes.marginButton
            )}
            onClick={handleSubmit}
            disabled={submitDisabled ? submitDisabled : false}
          >
            {submitText}
          </Button>
        )}
        {customButtonsRight.map(({ label, ...props }) => (
          <Button {...props}>{label}</Button>
        ))}
        {zohoSalesIQ?.enabled && <div className={classes.marginZohoButton} />}
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => ({
  isSidebarMiniModeActive: state.SidebarStatus.isSidebarMiniModeActive,
  zohoSalesIQ: state.Company.zohoSalesIQ,
});

export default connect(mapStateToProps)(FormControlStickyButton);
